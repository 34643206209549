<div class="student-meal-container">
  <div class="banner-text" *ngIf="isMobileView">
    {{ 'meal-title' | translate }}
  </div>
  <form [formGroup]="studentMealForm" class="meal-form-container">
    <div class="header">
      Enter your Code
    </div>
    <div class="group">
      <input type="text" required formControlName="code" required data-qa="student-meal-code"
        aria-label="student meal code" name="code" id="studentMealCode" />
      <label>{{ 'student-meal-code' | translate }}</label>
      <div *ngIf="
          studentMealForm.controls.code.touched &&
          !studentMealForm.controls.code.valid || isSubmit && !studentMealForm.controls.code.valid
        " class="alert">
        {{ 'meal-code-alert' | translate }}
      </div>
    </div>
    <div class="group" *ngIf="mealCodePayment">
      <ng-container *ngIf="mealCodePayment === 'loading' then loadingTemplate else paymentDetailsTemplate">
      </ng-container>
      <ng-template #loadingTemplate>Fetching the payable amount</ng-template>
      <ng-template #paymentDetailsTemplate>
        <ng-container *ngIf="mealCodePayment.isMealExemption">Taxes will be exempt.</ng-container>Your final payable
        amount is {{mealCodePayment?.price | formattedCurrency}}
      </ng-template>
    </div>

    <div class="row sign-in-button-container">
      <button class="btn-submit" (click)="continueDesktop()" data-qa="continue-step" type="button">
        {{ 'continue-adrs' | translate }}
      </button>
    </div>
  </form>
</div>