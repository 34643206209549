// angular
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClient, private store: Store) {}

  fetchHomeData(selectedLocationId , selectedDelivery): Observable<any> {   
    let selectedTime = new Date().toISOString();
    const futureTime = this.store.selectSnapshot(app => app.cart && app.cart.futureDate);
    if(futureTime)
      selectedTime = new Date(futureTime).toISOString();
    const timezoneOffset = new Date().getTimezoneOffset();
    let url = `${environment.locationApiUrl}locations/${selectedLocationId}/homeComponents`;
    url += `?selectedTime=${selectedTime}&timezoneOffset=${timezoneOffset}&selectedDelivery=${selectedDelivery}`;
    return this.http.get(url);
  }

  fetchReOrderItems(request): Observable<any> {   
    const futureTime = this.store.selectSnapshot(app => app.cart && app.cart.futureDate);
    const selectedTime = futureTime? new Date(futureTime).toISOString() :new Date().toISOString();
    const timezoneOffset = new Date().getTimezoneOffset();
    const organizationId = Config.organizationId;
    const delivery = this.store.selectSnapshot(app => app.delivery && app.delivery.selectedDelivery);
    const currDate = new Date();
    const url = `${environment.customerApiUrl}customers/organization/${organizationId}/reorder`;
    // const url = `http://localhost:8288/api/v1/customers/organization/${organizationId}/reorder`;

    const body = {
      delivery: request.deliveryId,
      deliveryMethod: request.deliveryMethod,
      selectedTime: selectedTime,
      timezoneOffset: timezoneOffset,
      locationId: request.locationId,
      fetchCount: request.fetchCount,
      customerId: request.customerId,
      organizationId,
      isFuture: !!futureTime,
      timeInfo: {
        locationId: request.locationId,
        type: delivery.textCode,
        currentTime: currDate.toISOString(),
        future: futureTime,
        timezoneOffset: currDate.getTimezoneOffset(),
        selectedTime: selectedTime,
      },
      deliveryAddress: null,
      customerPhoneNumber :request.customerPhoneNumber,
    };

    if( request.deliveryMethod === 'DELIVERYTYPE01' || request.deliveryMethod === 'DELIVERYTYPE04' ){
      // fetch and add delivery address here
      const storedAddress = localStorage.getItem('selectedAdrsLocation')
      body.deliveryAddress = storedAddress && storedAddress != 'undefined' ? JSON.parse(storedAddress) : undefined;
    }
    return this.http.post(url, body);
  }
}
