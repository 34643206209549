import {
  LocationState,
  AddressState,
  AuthState,
} from '@moduurnv2/libs-orderingapp/src/core/state';
import {
  ChangeToNewDeliveryAddress,
  UpdateAddressList,
  FetchAddressList,
  setLocationDistance,
  FetchDeliverableDetails,
  ChangeDeliveryAddress,
  ChangeDeliveryAddressLocal,
  FetchDeliveryAddress,
  SaveNewDeliveryAddress,
  FetchCart,
  SetEnteredAddress,
  ClearEnteredAddress,
  ClearSelectedAddress,
  ClearErrorMessagesOnAddressAddition,
  FetchETA
} from '@moduurnv2/libs-orderingapp/src/core/actions';
import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
import {
  AddressData,
  Address,
  AddressComponentCloseModel,
  AddressComponentEnums,
  AddressShort
} from '@moduurnv2/libs-orderingapp/src/core/models';
import { BaseComponent } from '@moduurnv2/libs-orderingapp/src/core/base/base-component';
import { Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core/services/sweet-alert-service';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil, last } from 'rxjs/operators';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core/services/shared.service';
import { Provinces } from '@moduurnv2/libs-orderingapp/src/core/services/province.service';
import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
export class AddressDetailsBaseComponent extends BaseComponent
  implements OnInit {
  @Input() modalAdrsDetailsStatus: boolean;
  @Input() isAddressBook: boolean;
  @Output() closeEvent = new EventEmitter();
  @Output() closeNewScreen = new EventEmitter<AddressComponentCloseModel>();
  @Input() data;
  @Input() fromPage:AddressComponentEnums;
  deliveryLocation: any;
  deliveryAddress: string;
  imgBasePath: string = environment.image_basepath;
  adrsType: number = 0;
  isValid: boolean = true;
  isValidStreet: boolean = true;
  isValidCity: boolean = true;
  isaddresDetails: boolean = true;
  houseNo: string = '';
  floor: string;
  instructions: string;
  selectedLocation: Address;
  otherAdrsType: string;
  zipCode: string;
  country: string;
  streetAddress: string;
  city: string;
  deliverabledata: any;
  countries = Provinces.countries;
  selectedCountry: string;
  selectedState: string;
  states: any;
  isAdrsTagValid: boolean = true;
  isFromAddressBook: Boolean = false;
  guestAddressLoc: any;
  listNotEmpty : Boolean = false;
  @Select(AddressState.getTempSelectedAddress)
  DeliveryAddress$: Observable<Address>;
  @Select(AddressState.getGuestAddressResponse)
  guestAddressResponse$: Observable<any>;
  @Select(AddressState.getDistance)
  deliveryDistance$: Observable<any>;
  @Select(AddressState.getLoggedInUserAddrSaveResponse) loggedInUserAddrSaveResponse$: Observable<any>;
  @Select(AddressState.getAddressList) addressList$: Observable<Address[]>;
  @Select(AddressState.getAddressListFromLocal)
  addressListLocal$: Observable<Address[]>;

  
  icon = {
    edit: this.imgBasePath + environment.images.edit,
    close: this.imgBasePath + environment.images.close,
    adrs_edit: this.imgBasePath + environment.images.edit_blue,
  };
  // _modalClose: any = null;
  // _modalCloseDetails: any = null;
  isMobileView: boolean = true;
  buzzerNo: string = '';
  addressSearched: AddressShort;
  constructor(
    public store: Store,
    public modal: ModalService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService,
    public router: Router,
    public shared: SharedService
  ) {
    super();
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    // this.setData(this.data);
    //this.validateCountry();
  }
  ngOnInit() {
    this.subscribeAdrs();
    let data = this.deliveryAddress ? this.deliveryAddress.split(',') : '';
    this.country = data != '' ? data[data.length - 1] : '';
    this.validateCountry();
    this.mapBoxInit();
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer && customer.customerId) {
      let addressList = this.store.selectSnapshot(AddressState.getAddressList);
      if( addressList && addressList.length){
        this.listNotEmpty = true;
      }
      // this.addressList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      //   console.log(data)
      //   if(data && data.length){
      //     this.listNotEmpty = true;
      //     console.log(data,"hello")
      //   }
       
      // });
    }else {
      this.addressListLocal$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if(data && data.length){
            this.listNotEmpty = true;
          }
        });
    }
  }

  mapBoxInit() {
    this.addressSearched = {
      streetAddress:undefined,
      postalcode: undefined,
      city:undefined,
      state:undefined,
      country:undefined,
      loc:undefined,
      unitNumber:undefined
    }
    Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(
      environment.mapbox.accessToken
    );
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      // types: 'country,region,place,postcode,locality,neighborhood'
      placeholder: 'Search your address here or type manually below'
      });
      geocoder.addTo('#geocoderSearch');
      // Get the geocoder results container.
      // const geocoderResult = document.getElementById('geocoderResult');
      // Add geocoder result to container.
      let scope = this;
      geocoder.on('result', function (results) {
        // geocoderResult.innerText = JSON.stringify(results.result, null, 2);
        scope.adressData(results.result);
      });
        
      // Clear results container when search is cleared.
      geocoder.on('clear', function () {
        // geocoderResult.innerText = '';
      }); 
  }

  adressData(result) {
    if (result != undefined) {

      for (let index in result.context) {
        if (result.context[index].id.includes('postcode'))
        this.addressSearched['postalcode'] = result.context[index].text;
        else if (result.context[index].id.includes('place'))
        this.addressSearched['city'] = result.context[index].text;
        else if (result.context[index].id.includes('region')) {
          this.addressSearched['state'] = result.context[index].text;
          this.addressSearched['regionCode'] = result.context[index].short_code;
        } else if (result.context[index].id.includes('country'))
        this.addressSearched['country'] = result.context[index].text;
      }
      this.addressSearched['loc'] = result.geometry.coordinates;
      this.addressSearched['streetAddress'] =
        (result.address ? result.address + ' ' : '') +
        (result.text ? result.text : '') +
        (result.properties?.address ? ',' + result.properties.address : '');
      // console.log(this.addressSearched);
      this.addressSearched['country'] = (this.addressSearched['country'] == 'United States') ? 'USA' : this.addressSearched['country'];
      this.setAddressFromSearchResponse(this.addressSearched)
    }
  }

  setAddressFromSearchResponse(addressSearched){
    this.streetAddress = addressSearched.streetAddress;
    this.city = addressSearched.city;
    this.zipCode = addressSearched.postalcode;
    this.selectedCountry = addressSearched.country;
    this.fetchStates(this.selectedCountry);
    setTimeout(()=>{
      this.selectedState = addressSearched.state;
    },500)
  }

  fetchStates(country) {
    this.selectedCountry = country;
    if(Provinces.states[this.selectedCountry])
    this.states = [
      ...new Map(
        Provinces.states[this.selectedCountry].map((item) => [item['n'], item])
      ).values(),
    ];
    this.states.unshift({ n: 'Select Province', v: 'Select Province' });
    this.selectedState = "Select Province"
    //this.selectedState = this.states[this.states.length - 1].n;
  }
  validateCountry() {
    let selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let address = localStorage.getItem('tempSelectedAdrsLocation')
      ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
      : undefined;
    if (address?.country && address.country != '') {
      this.deliveryAddress += ',' + address.country;
      if (address.country == 'United States') this.selectedCountry = 'USA';
      else this.selectedCountry = address.country;
      this.states = Provinces.states[this.selectedCountry];
      this.selectedState = this.formatProvinceFromShortName(this.selectedCountry,address.state);
    } else {
      this.selectedCountry = selectedLocation.addressObj
        ? selectedLocation.addressObj.country
        : 'USA';
      this.states = Provinces.states[this.selectedCountry];
      this.fetchStates(this.selectedCountry);
    }
  }
  validate(zip) {
    if (
      this.selectedCountry == 'Canada' ||
      this.selectedCountry == 'Canadá' ||
      this.selectedCountry == 'United States' ||
      this.selectedCountry == 'États Unis' ||
      this.selectedCountry == 'Estados Unidos' ||
      this.selectedCountry == 'USA' ||
      this.selectedCountry == 'Etats-Unis' ||
      this.selectedCountry == 'EE.UU' ||
      this.selectedCountry == 'Saudi Arabia' ||
      this.selectedCountry == 'Arabia Saudita' ||
      this.selectedCountry == 'Arabie Saoudite'
    ) {
      if (
        this.selectedCountry == 'Canada' ||
        this.selectedCountry == 'Canadá'
      ) {
        this.zipCode = zip.toUpperCase();
        let char = this.zipCode.charAt(3);
        if (char != ' ' && this.zipCode.length > 3)
          this.zipCode = [
            this.zipCode.slice(0, 3),
            ' ',
            this.zipCode.slice(3),
          ].join('');
        var zipRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      } else if (
        this.selectedCountry == 'United States' ||
        this.selectedCountry == 'États Unis' ||
        this.selectedCountry == 'Estados Unidos' ||
        this.selectedCountry == 'USA' ||
        this.selectedCountry == 'Etats-Unis' ||
        this.selectedCountry == 'EE.UU'
      )
        var zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
      else if (
        this.selectedCountry == 'Saudi Arabia' ||
        this.selectedCountry == 'Arabia Saudita' ||
        this.selectedCountry == 'Arabie Saoudite'
      )
        var zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
      this.isValid = zipRegExp.test(zip);
    } else this.isValid = true;
  }
  validateStreet(street) {
    if (street != '' || street.trim() != '') {
      this.isValidStreet = true;
    } else {
      this.isValidStreet = false;
    }
  }
  validateCity(city) {
    if (city != '' || city.trim() != '') {
      this.isValidCity = true;
    } else {
      this.isValidCity = false;
    }
  }
  // adrsvalidate(adrs) {
  //   if (adrs.trim() == '') this.isaddresDetails = false;
  //   else this.isaddresDetails = true;
  // }
  adrstagvalidate(tag) {
    if (tag?.trim() == '') this.isAdrsTagValid = false;
    else this.isAdrsTagValid = true;
  }
  subscribeAdrs() {
    let address = localStorage.getItem('tempSelectedAdrsLocation')
      ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
      : undefined;
    if (address) {
      this.deliveryLocation = address;
      if (address?.streetAddress && address.streetAddress != '') {
        this.deliveryAddress = address.streetAddress;
        this.streetAddress = address.streetAddress;
      }
      if (address?.city && address.city != '') {
        this.deliveryAddress += ',' + address.city;
        this.city = address.city;
      }
      if (address?.state && address.state != '')
        this.deliveryAddress += ',' + address.state;
      if (address?.country && address.country != '') {
        this.deliveryAddress += ',' + address.country;
        if (address.country == 'United States') this.selectedCountry = 'USA';
        else this.selectedCountry = address.country;
        this.states = Provinces.states[this.selectedCountry];
        this.selectedState = address.state;
      }
      this.houseNo = address.unitNumber;
      this.buzzerNo = address.buzzerNumber;
      this.instructions = address.instructions;
      if (address.addressType != '') {
        if (address.addressType == 'Home') this.adrsType = 1;
        else if (address.addressType == 'Office') this.adrsType = 2;
        else {
          this.adrsType = 3;
          this.otherAdrsType = address.addressType;
        }
      } else {
        this.adrsType = 0;
        this.otherAdrsType = '';
      }
      this.zipCode = address.postalcode ? address.postalcode : '';
      if (this.zipCode != '') this.validate(this.zipCode);
    }
  }
  selectAdrsType(type) {
    if (this.adrsType == type) this.adrsType = 0;
    else this.adrsType = type;
    if (this.adrsType == 3) this.adrstagvalidate(this.otherAdrsType);
    else this.isAdrsTagValid = true;
  }
  async saveAdrs(saveAddressBtn) {
    // if (this.houseNo.trim() == '') this.isaddresDetails = false;
    // else this.isaddresDetails = true;
    this.store.dispatch(new ClearEnteredAddress());
    this.store.dispatch(new ClearErrorMessagesOnAddressAddition());
    this.validate(this.zipCode);
    // if (this.isValid && this.isaddresDetails) {
    if (this.isValid && this.isValidCity && this.isValidStreet) {
      // this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
      // this.store.dispatch(new ChangePopupStatus(false));
      // this.store.dispatch(new ChangeMapPopupStatus(false));
      let address = localStorage.getItem('tempSelectedAdrsLocation')
        ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
        : undefined;
      // this.selectedLocation = address;
      if (!address)
        this.selectedLocation = {
          addressType: '',
          instructions: '',
          buzzerNumber: '',
          unitNumber: '',
          postalcode: '',
          country: '',
          state: '',
          city: '',
          _id: '',
          streetAddress: '',
          distance: '',
        };
      else this.selectedLocation = address;
      this.selectedLocation.unitNumber = this.houseNo;
      this.selectedLocation.buzzerNumber = this.buzzerNo;
      this.selectedLocation.instructions = this.instructions;
      this.selectedLocation.postalcode = this.zipCode;
      this.selectedLocation.city = this.city;
      this.selectedLocation.addressType = '';
      this.selectedLocation.country =
        this.selectedCountry == 'United States' ? 'USA' : this.selectedCountry;
      this.selectedLocation.state = this.formatProvinceFromLongName(this.selectedLocation.country,this.selectedState);
      this.selectedLocation.streetAddress = this.streetAddress;
      this.selectedLocation['disableAddressChecking'] = this.disableAddressChecking()

      if (this.adrsType != 0) {
        if (this.adrsType == 3)
          this.selectedLocation.addressType = this.otherAdrsType;
        else
          this.selectedLocation.addressType =
            this.adrsType == 1 ? 'Home' : 'Office';
      }

      this.startLoader(saveAddressBtn.id, saveAddressBtn.innerText);
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if (!customer?.isLoggedIn) 
      this.store.dispatch(
        new ChangeToNewDeliveryAddress(this.selectedLocation)
      );
      let previousLocs = [];
      if (customer?.isLoggedIn) {     
        if(this.selectedLocation['disableAddressChecking']){
          let addressSelectedFromMap = localStorage.getItem('tempSelectedAdrsLocation')
            ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
            : undefined;
            if(addressSelectedFromMap && (addressSelectedFromMap._id=='' || addressSelectedFromMap._id == this.selectedLocation['id']))
            this.selectedLocation['loc']=addressSelectedFromMap.loc;
        }
        let saveAddressSubs = await this.store.dispatch(new SaveNewDeliveryAddress(this.selectedLocation)).toPromise();
          // .subscribe((data) => {
            
        this.loggedInUserAddrSaveResponse$.pipe(takeUntil(this.destroy$)).subscribe( async (response)=>{
          if(response){
            if(response?.errorCode == '4003' || response.status==400){
              this.openMap();
            } else {
              let errorMessagesToDisplay = response?.data?.[0]?.loc?.errorData;
              if(errorMessagesToDisplay && errorMessagesToDisplay?.length){
                this.stopLoader(saveAddressBtn.id);
                this.displayErrorMessagesOnAddressAddition(errorMessagesToDisplay);  
              } else 
              {
              let deliveryAddress = response.data.deliveryAddress
            // this.store
            //   .dispatch(new FetchAddressList())
            //   .subscribe(async (locations) => {
                let lastdata = deliveryAddress.length - 1;
                if(!this.selectedLocation._id)
                this.selectedLocation._id = deliveryAddress.length
                  ? deliveryAddress[lastdata]._id
                  : '';
                this.saveEnteredAddressToStore();
                this.selectedLocation.loc =  deliveryAddress.length? deliveryAddress[lastdata].loc:undefined;

                // if(!(this.selectedLocation.loc?this.selectedLocation.loc.length:1))
                // this.selectedLocation.loc = locations.address.addressList.length
                //   ? locations.address.addressList[lastdata].loc
                //   : [];

                // this.checkDeliverable(this.selectedLocation);
                const selectedOrganizationLocation = this.store.selectSnapshot(
                  LocationState.getSelectedLocation
                );
                let addressDeliverableCheckResult;
                if (selectedOrganizationLocation)
                  addressDeliverableCheckResult = await this.checkDeliverable(
                    this.selectedLocation
                  );
                // this.store.dispatch(new ChangePopupStatus(true));
                // if (this.isFromAddressBook) {
                //   // this.closeDetails();
                // }
                if(addressDeliverableCheckResult.address.enteredAddress)
                  this.setSelectedAddressToStore(addressDeliverableCheckResult.address.enteredAddress,addressDeliverableCheckResult.address.isDeliverable)
                this.actionCloseModal(addressDeliverableCheckResult);
                this.stopLoader(saveAddressBtn.id);
              // });
            }
              }
            }
            })
          // });
      } else {
        if (!this.selectedLocation._id)
          this.selectedLocation._id = String(new Date().getUTCMilliseconds());
          this.saveEnteredAddressToStore()
        // this.store.dispatch(
        //   new ChangeToNewDeliveryAddress(this.selectedLocation)
        // );
        const selectedOrganizationLocation = this.store.selectSnapshot(
          LocationState.getSelectedLocation
        );
        let addressDeliverableCheckResult;
        if (selectedOrganizationLocation)
          addressDeliverableCheckResult = await this.checkDeliverable(
            this.selectedLocation
          );
        if ('locationList' in localStorage)
          previousLocs = JSON.parse(localStorage.getItem('locationList'));
        if(!previousLocs){
          previousLocs=[];
        }
        if(previousLocs?.length > 0){
          let index = previousLocs.findIndex((adrs) =>
            adrs._id == this.deliveryLocation ? this.deliveryLocation._id : 0
          );
          if (index >= 0) previousLocs.splice(index, 1);
          var vm = this;
          let pointer = previousLocs.findIndex(function (o) {
            return o._id === vm.selectedLocation._id;
          });
          if (pointer !== -1) previousLocs.splice(pointer, 1);
        }
        if (this.selectedLocation?.loc) {
          let lng = undefined;
          let lat = undefined;
          if(this.selectedLocation?.loc['lng'] && this.selectedLocation?.loc['lat'] ){
            lng = this.selectedLocation.loc['lng']
            lat = this.selectedLocation.loc['lat']
          } else if(this.selectedLocation?.loc?.length > 0){
            lng = this.selectedLocation.loc[0]
            lat = this.selectedLocation.loc[1]
          }
          this.selectedLocation.loc = [lng,lat];
        }

        
        previousLocs.push(this.selectedLocation);
        localStorage.setItem('locationList', JSON.stringify(previousLocs));
        if(!addressDeliverableCheckResult?.address?.errorMessages?.length){
        this.store.dispatch(new UpdateAddressList(previousLocs));
        }
        // this.store.dispatch(new ChangePopupStatus(true));
        if (addressDeliverableCheckResult){
          this.setSelectedAddress(addressDeliverableCheckResult);
        }
        this.actionCloseModal(addressDeliverableCheckResult);
        this.stopLoader(saveAddressBtn.id);
      }
    }
  }

  saveEnteredAddressToStore(){
    let enteredAddressBody = {
      id: this.selectedLocation._id,
      unitNumber: this.selectedLocation['unitNumber'],
      country: this.selectedLocation['country'],
      postalcode: this.selectedLocation['postalcode'],
      state: this.selectedLocation['state'],
      city: this.selectedLocation['city'],
      streetAddress: this.selectedLocation['streetAddress'],
      //disableAddressChecking is used to disable google address validation if address already taken from map / is in edit mode
      disableAddressChecking: this.disableAddressChecking()
    }
    if(enteredAddressBody.disableAddressChecking){
      let addressSelectedFromMap = localStorage.getItem('tempSelectedAdrsLocation')
        ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
        : undefined;
        if(addressSelectedFromMap && (addressSelectedFromMap._id=='' || addressSelectedFromMap._id == enteredAddressBody.id))
        enteredAddressBody['loc']=addressSelectedFromMap.loc;
        else if(this.addressSearched?.streetAddress)
        enteredAddressBody['loc']=this.addressSearched.loc;
    }
    this.store.dispatch(new SetEnteredAddress(enteredAddressBody))
  }

  addressUnchangedAfterMapSelection(){
    let addressSelectedFromMap = localStorage.getItem('tempSelectedAdrsLocation')
        ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
        : undefined;
    let addressEntered = this.selectedLocation;
    if(addressSelectedFromMap && addressEntered &&
      addressSelectedFromMap.streetAddress == addressEntered.streetAddress &&
      addressSelectedFromMap.city == addressEntered.city &&
      this.compareStates(addressSelectedFromMap,addressEntered) &&
      this.compareCountries(addressSelectedFromMap,addressEntered) &&
      addressSelectedFromMap.postalcode == addressEntered.postalcode)
      return true;
      else
      return false;
  }

  addressUnchangedAfterSearched(){
    let addressEntered = this.selectedLocation;
    if(this.addressSearched && addressEntered && this.addressSearched.streetAddress && 
      this.addressSearched.streetAddress == addressEntered.streetAddress &&
      this.addressSearched.city == addressEntered.city &&
      this.compareStates(this.addressSearched,addressEntered) &&
      this.compareCountries(this.addressSearched,addressEntered) &&
      this.addressSearched.postalcode == addressEntered.postalcode)
      return true;
      else
      return false;
  }

  formatProvinceFromLongName(selectedCountry,selectedProvince){
    let provinces = Provinces.states;
    if(provinces?.[selectedCountry]){
      let foundProvince = provinces[selectedCountry].find(c=> c.n ==selectedProvince)
      if(foundProvince)
      return foundProvince['v']
    } else {
      return selectedProvince;
    }
    return selectedProvince;
  }

  formatProvinceFromShortName(selectedCountry,selectedProvince){
    let provinces = Provinces.states;
    if(provinces?.[selectedCountry]){
      let foundProvince = provinces[selectedCountry].find(c=> c.v ==selectedProvince)
      if(foundProvince)
      return foundProvince['n']
    } else {
      return selectedProvince;
    }
    return selectedProvince;
  }


  compareCountries(addressSelectedFromMap,addressEntered){
    if((addressSelectedFromMap.country =='United States' || addressSelectedFromMap.country =='USA') && (addressEntered.country =='United States' || addressEntered.country =='USA'))
    return true;
    else if(addressSelectedFromMap.country == addressEntered.country)
    return true;
    else
    return false;
  }

  compareStates(addressSelectedFromMap,addressEntered){
    let selectedCountry =addressEntered.country;
    if((addressSelectedFromMap.country =='United States' || addressSelectedFromMap.country =='USA') && (addressEntered.country =='United States' || addressEntered.country =='USA'))
    selectedCountry = 'USA'
    // console.log('addressSelectedFromMap->',addressSelectedFromMap.state);
    // console.log('addressEntered->',addressEntered.state);
    if(this.formatProvinceFromLongName(selectedCountry,addressSelectedFromMap.state) == addressEntered.state)
    return true;
    else
    return false;
  }

  setSelectedAddress(addressDetails) {
    let isDeliverable = addressDetails?.address?.isDeliverable;
    if (isDeliverable) {
      if (this.selectedLocation)
        localStorage.setItem(
          'selectedAdrsLocation',
          JSON.stringify(this.selectedLocation)
        );
      else localStorage.removeItem('selectedAdrsLocation');
      this.store.dispatch(new FetchDeliveryAddress());
    }
  }
  setSelectedAddressToStore(enteredAddress,isDeliverable) {
    let updatedAddress = enteredAddress
    updatedAddress["isDeliverable"] = isDeliverable
    updatedAddress["addressType" ] = ""
    updatedAddress["_id" ] = enteredAddress.id

    if (isDeliverable) {
      if (updatedAddress)
        localStorage.setItem(
          'selectedAdrsLocation',
          JSON.stringify(updatedAddress)
        );
      else localStorage.removeItem('selectedAdrsLocation');
      this.store.dispatch(new FetchDeliveryAddress());
    }
  }
  
  displayErrorMessagesOnAddressAddition(errorMessagesToDisplay,checkingAddress?){
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      let selectedAddress = this.store.selectSnapshot(AddressState.getSelectedAddress);
      let previousLocs = JSON.parse(localStorage.getItem('locationList'));
      if(errorMessagesToDisplay[0].errorCode == 2001){
        this.zipCode ='';
        this.isValid = false;
        this.sweetAlert.toast('error',errorMessagesToDisplay[0].errorMessage);
        this.store.dispatch(new ClearErrorMessagesOnAddressAddition());
        if(selectedAddress && checkingAddress && checkingAddress?.id == selectedAddress?._id){
          localStorage.removeItem('selectedAdrsLocation')
          this.store.dispatch(new ClearSelectedAddress());
        }
        if (!customer?.isLoggedIn && previousLocs?.length) { 
            previousLocs.splice(previousLocs.length-1,1)
              localStorage.setItem('locationList',JSON.stringify(previousLocs));
            }
       }
  }

  actionCloseModal(addressDetails) {
    let isDeliverable = addressDetails?.address?.isDeliverable;
    let isInvalidAddress = addressDetails?.address?.isInvalidAddress;
    let errorMessagesToDisplay = addressDetails?.address?.errorMessages;
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    // this.store.dispatch(new ClearEnteredAddress());
    let selectedFromPage = this.router.url.split('/').pop();

    // if(isInvalidAddress && errorMessagesToDisplay?.length){
    if(errorMessagesToDisplay?.length){
      let checkingAddress;
      if(!customer?.isLoggedIn){
        checkingAddress = addressDetails?.address?.guestAddressResponse;
      }
      this.displayErrorMessagesOnAddressAddition(errorMessagesToDisplay,checkingAddress);  
    } else 
    if (this.isMobileView) {
      if (isDeliverable && !isInvalidAddress) {
        this.setAddressDistanceFromLocation(selectedFromPage);
        // this.store.dispatch(new ChangePopupStatus(false));
        // this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
        // this.store.dispatch(new ChangeMapPopupStatus(false));
        // this.modal.closeAllModals();
        this.closePage();
      } else  if(isInvalidAddress){
        // localStorage.removeItem('tempSelectedAdrsLocation');
        this.openMap({
              modalMapStatus: true,
              isAddressBook: true,
              addressData: this.selectedLocation,
            });
        // this.modal.showSmallModal(AddressMapComponent, {
        //   data: {
        //     modalMapStatus: true,
        //     isAddressBook: true,
        //     addressData: this.selectedLocation,
        //   },
        //   whenClosed: (data) => {
        //     if (data) {
        //       this.subscribeAdrs();
        //       let data = this.deliveryAddress
        //         ? this.deliveryAddress.split(',')
        //         : '';
        //       this.country = data != '' ? data[data.length - 1] : '';
        //       this.validateCountry();
        //     }
        //   },
        // });
      }
      else
      {
        //this.modal.closeAllModals();
        //localStorage.removeItem('tempSelectedAdrsLocation'); // may be we can remove this later
        // this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
        //this.store.dispatch(new ChangeMapPopupStatus(false));
        // if(this.isAddressBook)
        // this.store.dispatch(new ChangeMapPopupStatus(false));
        // this.store.dispatch(new ChangePopupStatus(true));

        this.closeDetailModalOnly();
      }
    } else {
      if (isDeliverable && !isInvalidAddress) {
        this.setAddressDistanceFromLocation(selectedFromPage);
        this.closePage();
        // this.modal.closeAllModals();
      } else if(isInvalidAddress){
        // localStorage.removeItem('tempSelectedAdrsLocation');
       // if(this.selectedLocation.loc?this.selectedLocation.loc.length:0){
        //  if(isInvalidAddress)
        // if(!(this.selectedLocation.loc?this.selectedLocation.loc.length:1))
        this.openMap({addressData: this.selectedLocation});
        // this.modal.showMediumModalDesktop(AddressMapDesktopComponent, {
        //   data: { addressData: this.selectedLocation },
        //   whenClosed: (data) => {
        //     if (data) {
        //       this.subscribeAdrs();
        //       let data = this.deliveryAddress
        //         ? this.deliveryAddress.split(',')
        //         : '';
        //       this.country = data != '' ? data[data.length - 1] : '';
        //       this.validateCountry();
        //     }
        //   },
        // });
        // else this.closeDetails();
    //  }
      } else this.closeDetailModalOnly();
      //  else
      // if((selectedFromPage != 'user-profile') && (selectedFromPage != 'home'))
      // this.closeDetails();
      // else
      // {
      //   this.modal.closeAllModals()
      // }
    }
  }

  setAddressDistanceFromLocation(selectedFromPage){
    this.deliveryDistance$.pipe(takeUntil(this.destroy$)).subscribe(distance=>{
      if(distance >= 0){
        localStorage.setItem('locationDistance',distance);
        if(selectedFromPage == 'order'){
          setTimeout(()=>{this.store.dispatch(new FetchCart())},1200)
        }
        
      }
    })
  }
 closeAll(){
  localStorage.removeItem('tempSelectedAdrsLocation');
  // this.store.dispatch(new ChangePopupStatus(false));
  // this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
  // this.store.dispatch(new ChangeMapPopupStatus(false));
  // this.modal.closeAllModals();
  this.closePage();
 }

//  goBack(){
//   const state = this.store.selectSnapshot((app) => app);
//   const { auth, address,venuemanagement } = state;
//   if(this.isAddressBook)
//   this.closePage();
//   else if(auth?.loggedInUser?.isLoggedIn){
//     if(address?.addressList?.length)
    
//   }
//  }
  // closeDetails() {
  //   if(this.isAddressBook)
  //   localStorage.removeItem('tempSelectedAdrsLocation');
  //   // this.modal.keepUserDefinedModalsAndCloseOthers(1);
  //   // if (this._modalClose) this._modalClose();
  // }

  closeDetailModalOnly(event?) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    localStorage.removeItem('tempSelectedAdrsLocation');
    if(this.isAddressBook)
    this.closePage()
    else
    this.openList()
    // this.modal.keepUserDefinedModalsAndCloseOthers(1);
    // if (this._modalClose) this._modalClose();
  }
  
  // closeAddressDetails() {
  //   if (this._modalCloseDetails) this._modalCloseDetails();
  // }
  // set modalClose(modalRef: any) {
  //   this._modalClose = () => {
  //     this.modal.closeUserDefinedComponentModals(1);
  //   };
  // }
  // set modalCloseDetails(modalRef: any) {
  //   this._modalCloseDetails = () => {
  //     this.modal.closeModal(modalRef);
  //   };
  // }
  
  async checkDeliverable(adrsList) {
    return new Promise(async (resolve) => {
      const selectedLocation = this.store.selectSnapshot(
        LocationState.getSelectedLocation
      );
      let adrsdataObj: AddressData = {
        locationId: selectedLocation._id,
        locationCoordinates: selectedLocation['loc'],
        addressCoordinates: [],
      };
      adrsdataObj.addressCoordinates.push({
        id: adrsList._id,
        loc: {
          lng: adrsList['loc'] ? adrsList['loc'][0] : undefined,
          lat: adrsList['loc'] ? adrsList['loc'][1] : undefined,
        },
        details:{
          city:adrsList.city,
          state:adrsList.state,
          streetAddress:adrsList.streetAddress,
          unitNumber:adrsList.unitNumber,
          postalcode:adrsList.postalcode,
          country:adrsList.country
        }
      });

      let addressStatus;
      // if (customer && customer.customerId) {
      //   addressStatus = await this.store
      //   .dispatch(new FetchDeliveryAddressDetails(adrsdataObj))
      // } else{
        addressStatus = await this.store
        .dispatch(new FetchDeliverableDetails(adrsdataObj))
        .toPromise();
     // }
      if (addressStatus) {
        this.check(addressStatus);
      }
      resolve(addressStatus);
      // .then((data) => this.check(data));
    });
  }


  check(data) {
    if (data.address.isDeliverable) {
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if (customer && customer.customerId) {
        this.store.dispatch(new FetchAddressList()).subscribe(result=>{
          this.store.dispatch(
            new ChangeDeliveryAddress(this.selectedLocation._id)
          ).subscribe(result1=>{
            this.store.dispatch(new FetchETA());
          });
        });
      } else {
        this.guestAddressLoc = data.address.guestAddressResponse
          ? data.address.guestAddressResponse.loc
          : undefined;
        if (this.guestAddressLoc)
          this.selectedLocation.loc = this.guestAddressLoc;
        this.store.dispatch(
          new ChangeDeliveryAddressLocal(this.selectedLocation._id)
        );
      }
      let selectedLocation = localStorage.getItem('selectedLocation')
        ? JSON.parse(localStorage.getItem('selectedLocation'))
        : undefined;
      if (selectedLocation) {
        this.store.dispatch(new setLocationDistance(selectedLocation._id));
      }
      localStorage.removeItem('tempSelectedAdrsLocation');
    } else if(!data.address.isInvalidAddress){
      this.showNotDeliverable();
    }
  }
  showNotDeliverable(){
    this.isFromAddressBook =
        this.router.url.split('/').pop() === 'user-profile';
      if (!this.isFromAddressBook) {
        this.sweetAlert.toast(
          'error',
          this.translate.instant('not-deliverable-adrs')
        );
      }
      localStorage.removeItem('selectedAdrsLocation');
  }
  // openMap() {
  //   this.store.dispatch(new ChangeMapPopupStatus(true));
  // }
  // setData(data: any) {
  //   if(data){
  //     this.modalAdrsDetailsStatus = data.modalAdrsDetailsStatus;
  //     this.isAddressBook = data.isAddressBook;
  //   }
  // }

  closePage(){
    this.closeNewScreen.emit({toPage:AddressComponentEnums.NONE,fromPage:AddressComponentEnums.ADDRESS_DETAILS});
  }

  openList(data?){
    this.closeNewScreen.emit({toPage:AddressComponentEnums.ADDRESS_LIST,fromPage:AddressComponentEnums.ADDRESS_DETAILS,dataToPass:data});
  }

  openMap(data?){
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    let previousLocs = JSON.parse(localStorage.getItem('locationList'));
    let selectedAddress = this.store.selectSnapshot(AddressState.getSelectedAddress);
    let addressInLocalStorage = localStorage.getItem('selectedAdrsLocation') ? JSON.parse(localStorage.getItem('selectedAdrsLocation')):undefined;
    localStorage.removeItem('tempSelectedAdrsLocation');
    if (customer?.isLoggedIn) {

      } else {
        if(previousLocs?.length){
          if(selectedAddress && previousLocs[previousLocs.length-1] && previousLocs[previousLocs.length-1]._id == selectedAddress._id){
            this.clearSelecteAddressEvent();
          }
          previousLocs.splice(previousLocs.length-1,1)
          if(previousLocs.length){
            localStorage.setItem('locationList',JSON.stringify(previousLocs));
          }
          else
          localStorage.removeItem('locationList')
          this.store.dispatch(new UpdateAddressList());
        } else {
          this.clearSelecteAddressEvent();
        }
      }

    this.closeNewScreen.emit({toPage:AddressComponentEnums.ADDRESS_MAP,fromPage:AddressComponentEnums.ADDRESS_DETAILS,dataToPass:data});
  }

  clearSelecteAddressEvent(){
    localStorage.removeItem('selectedAdrsLocation');
    this.store.dispatch(new ClearSelectedAddress());
  }


  disableAddressChecking(){
    if((this.fromPage == AddressComponentEnums.ADDRESS_MAP && this.addressUnchangedAfterMapSelection()) || this.addressUnchangedAfterMapSelection() || this.addressUnchangedAfterSearched())
      return true
      else 
      return false
  }

}
