import {
  State,
  Selector,
  StateContext,
  Action,
  Store,
  Select,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  AuthEmailLoginResponse,
  Address,
  Auth,
  AuthEmail,
  AuthEmailSignUpResponse,
  AuthEmailGenericResponse,
  UpdateProfileResponse,
  UserAvailabilityResponse,
  UserAvailability,
} from '../models';
import {
  setUserDetailsOnLogin,
  ClearLoginDetails,
  setExistingLoginData,
  SetCommonAuthDetails,
  SetUserDetailsOnSignUp,
  SetForgotPasswordRequest,
  CheckResetLinkValid,
  ClearExistingLoginData,
  ClearSignupData,
  ClearForgotPasswordData,
  ClearResetLinkValid,
  ResetPassword,
  ClearResetPasswordResponse,
  UpdateUserProfileData,
  checkUserAvailability,
  FetchGiftLoyaltyCards,
  setLoggedInUserDetails,
  SetEmptyLoginDetails,
  UpdateCartDetails,
  ClearUpdateProfileData,
  DeleteProfile,
  ClearCart
} from '../actions';
import { Injectable } from '@angular/core';
import { AuthEmailService } from '../services';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs';
import { CartState } from './cart.state';
import { AuthState } from './auth.state';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Router } from '@angular/router';
import { WhitelabelState } from './whitelabel.state';
export class AuthEmailLoginResponseModel {
  userData: AuthEmailLoginResponse;
  userInfo: AuthEmailLoginResponse;
  cartId: string;
  locationId: string;
  username: string;
  isLoggedIn: boolean;
  deliveryAddress: Address[];
  ordertype: string;
  ordertypeId: string;
  loginData: string;
  status: number;
  msg: string;
}

export class AuthEmailStateModel {
  login: AuthEmailLoginResponseModel;
  signUp: AuthEmailSignUpResponse;
  customerId: string;
  forgotPassword: AuthEmailGenericResponse;
  resetPasswordLinkValidity: AuthEmailGenericResponse;
  existingLoginData: string;
  resetPassword: AuthEmailGenericResponse;
  updateUserProfile: UpdateProfileResponse;
  userName: UserAvailability;
  userAvailabilitiy: UserAvailabilityResponse;
}

@State<AuthEmailStateModel>({
  name: 'authEmail',
  defaults: {
    login: {
      userData: {
        access_token: undefined,
        cart_id: undefined,
        dateOfBirth: undefined,
        deliveryAddress: [],
        email: undefined,
        expires_in: undefined,
        favourite_id: undefined,
        fname: undefined,
        lname: undefined,
        location_id: undefined,
        paymentGateway: undefined,
        primaryPhone: undefined,
        refresh_token: undefined,
        scope: undefined,
        token_type: undefined,
        user_id: undefined,
        isLoggedIn: false,
      },
      userInfo: {
        access_token: undefined,
        cart_id: undefined,
        dateOfBirth: undefined,
        deliveryAddress: [],
        email: undefined,
        expires_in: undefined,
        favourite_id: undefined,
        fname: undefined,
        lname: undefined,
        location_id: undefined,
        paymentGateway: undefined,
        primaryPhone: undefined,
        refresh_token: undefined,
        scope: undefined,
        token_type: undefined,
        user_id: undefined,
        isLoggedIn: false,
      },
      cartId: undefined,
      locationId: undefined,
      username: undefined,
      isLoggedIn: false,
      deliveryAddress: [],
      ordertype: undefined,
      ordertypeId: undefined,
      loginData: undefined,
      status: undefined,
      msg: undefined,
    },
    signUp: {
      allowLogin: false,
      msg: undefined,
      status: undefined,
      user_id: undefined,
      validationSent: false,
    },
    customerId: undefined,
    forgotPassword: undefined,
    resetPasswordLinkValidity: undefined,
    existingLoginData: undefined,
    resetPassword: undefined,
    updateUserProfile: undefined,
    userName: undefined,
    userAvailabilitiy: undefined,
  },
})
@Injectable()
export class AuthEmailState {
  @Select(AuthEmailState.getUserDetails) authEmail$: Observable<
    AuthEmailLoginResponse
  >;
  constructor(
    private _authEmailService: AuthEmailService,
    private store: Store,
    private router: Router
  ) {}

  @Selector()
  static getFullLoginDetails(state: AuthEmailStateModel) {
    return state.login;
  }

  @Selector()
  static getUserDetails(state: AuthEmailStateModel) {
    return state.login.userData;
  }
  @Selector()
  static getLoginData(state: AuthEmailStateModel) {
    return state.existingLoginData;
  }

  @Selector()
  static getUserInfo(state: AuthEmailStateModel) {
    return state.login.userInfo;
  }
  @Selector()
  static getCartId(state: AuthEmailStateModel) {
    return state.login.cartId;
  }
  @Selector()
  static getLocationId(state: AuthEmailStateModel) {
    return state.login.locationId;
  }
  @Selector()
  static getUsername(state: AuthEmailStateModel) {
    return state.login.username;
  }
  @Selector()
  static isLoggedIn(state: AuthEmailStateModel) {
    return state.login.isLoggedIn;
  }
  @Selector()
  static getDeliveryAddress(state: AuthEmailStateModel) {
    return state.login.deliveryAddress;
  }
  @Selector()
  static getOrdertype(state: AuthEmailStateModel) {
    return state.login.ordertype;
  }
  @Selector()
  static getOrdertypeId(state: AuthEmailStateModel) {
    return state.login.ordertypeId;
  }

  @Action(setUserDetailsOnLogin)
  setUserDetailsOnLogin(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: setUserDetailsOnLogin
  ) {
    payload['grant_type'] = this.defaultsForLogin().grant_type;
    payload['scopes'] = this.defaultsForLogin().scopes;
    payload['organizationId'] = Config.organizationId;
    const otherConfig = this.store.selectSnapshot(WhitelabelState.getOther);
    return this._authEmailService.loginUserEmail(payload).pipe(
      tap((response: any) => {
        if (
          response &&
          response.status !== 401 &&
          response.status !== 409 &&
          response.status !== 404
        ) {
          const selectedDelivery = this.store.selectSnapshot(
            (app) => app && app.delivery && app.delivery.selectedDelivery
          );
          const detailsForCommonAuth: Auth = {
            isLoggedIn: true,
            userName: response.username
              ? response.username
              : response.fname + ' ' + response.lname,
            loginProvider: 'EMAIL',
            imgUrl: undefined,
            cartId: response.cart_id ? response.cart_id : undefined,
            primaryPhone: response.primaryPhone
              ? response.primaryPhone
              : undefined,
            customerId: response.user_id,
            agreeToTerms: true,
            customerRegistryPhoneNumber : undefined,
          };
          if(response.cart_id){
            let body = {guest:false}
            this.store.dispatch(new UpdateCartDetails(body,response.cart_id));
          }
          this.store.dispatch(new SetCommonAuthDetails(detailsForCommonAuth));
          const resp = {
            status: response.status ? response.status : 200,
            userData: response,
            userInfo: response,
            cartId: response.cart_id ? response.cart_id : undefined,
            locationId: response.location_id ? response.location_id : undefined,
            username: response.username,
            isLoggedIn: response ? true : false,
            deliveryAddress: response.deliveryAddress
              ? response.deliveryAddress
              : [],
            ordertype: selectedDelivery ? selectedDelivery.textCode : undefined,
            ordertypeId: selectedDelivery ? selectedDelivery._id : undefined,
          };
          setState(
            patch({
              login: resp,
            })
          );
          if(otherConfig.isForceLogin){
            this.router.navigate(['locations']);
          }
        } else if (response.status != 200) {
          const resp = response;
          setState(
            patch({
              login: resp,
            })
          );
        }
      })
    );
  }

  // To clear all login related details
  @Action(ClearLoginDetails)
  ClearLoginDetails({ patchState }: StateContext<any>, {}: ClearLoginDetails) {
    patchState({
      login: undefined,
    });
  }

  // To clear all login related details except cart details
  @Action(SetEmptyLoginDetails)
  setEmptyLoginDetails({ patchState }: StateContext<any>, {}: ClearLoginDetails) {
    patchState({
      login: undefined,
    });
  }

  @Action(setExistingLoginData)
  setExistingLoginData(
    { setState }: StateContext<any>,
    { payload }: setExistingLoginData
  ) {
    setState({
      existingLoginData: payload,
    });
  }

  @Action(ClearExistingLoginData)
  ClearExistingLoginData(
    { patchState }: StateContext<any>,
    {}: ClearExistingLoginData
  ) {
    patchState({
      existingLoginData: undefined,
    });
  }

  @Selector()
  static getCustomerId(state: AuthEmailStateModel) {
    return state.customerId;
  }

  @Selector()
  static getSignUpData(state: AuthEmailStateModel) {
    return state.signUp;
  }

  @Selector()
  static getUpdateProfileData(state: AuthEmailStateModel) {
    return state.updateUserProfile;
  }

  @Action(ClearSignupData)
  ClearSignupData({ patchState }: StateContext<any>, {}: ClearSignupData) {
    patchState({
      signUp: undefined,
    });
  }

  @Action(ClearUpdateProfileData)
  ClearUpdateProfileData({ patchState }: StateContext<any>, {}: ClearUpdateProfileData) {
    patchState({
      updateUserProfile: undefined,
    });
  }

  @Action(SetUserDetailsOnSignUp)
  SetUserDetailsOnSignUp(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: SetUserDetailsOnSignUp
  ) {
    let signUpBody = payload;
    signUpBody['title'] = 1;
    signUpBody['organizationId'] = Config.organizationId;
    const activeCart = this.store.selectSnapshot(CartState.getCart);
    if (activeCart && activeCart.cart_id) {
      signUpBody['cart_id'] = activeCart.cart_id;
    }

    return this._authEmailService.signUpUserEmail(signUpBody).pipe(
      tap((response: any) => {
        if (response) {
          if (response.status == 200) {
            if (response.validationSent) {
              console.error(
                'Your account needs to be verified before you can access some features. An email containing an authorization link will be send to your email account.'
              );
            }
            if (!response.allowLogin) {
            } else {
              const loginBody: AuthEmail = {
                email: signUpBody.email,
                username: signUpBody.email,
                password: signUpBody.password,
                organizationId: signUpBody.organizationId,
                grant_type: this.defaultsForLogin().grant_type,
                scopes: this.defaultsForLogin().scopes,
              };
              this.store.dispatch(new setUserDetailsOnLogin(loginBody));
            }
          } else if (response.status === 400) {
            console.error(response.msg);
          } else {
            console.error('Unable to create user due to some problems');
          }
          setState(
            patch({
              customerId: response.user_id,
              signUp: response,
            })
          );
        } else {
          console.error('Unable to create user due to some problems');
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Selector()
  static getForgotPasswordResponse(state: AuthEmailStateModel) {
    return state.forgotPassword;
  }

  @Action(ClearForgotPasswordData)
  ClearForgotPasswordData(
    { patchState }: StateContext<any>,
    {}: ClearForgotPasswordData
  ) {
    patchState({
      forgotPassword: undefined,
    });
  }

  @Action(SetForgotPasswordRequest)
  SetForgotPasswordRequest(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: SetForgotPasswordRequest
  ) {
    let request = payload;
    request['organizationId'] = Config.organizationId;
    request['option'] = 'email';
    return this._authEmailService.forgotPasswordRequest(request).pipe(
      tap(
        (response: any) => {
          if (response && response.status) {
            setState(
              patch({
                forgotPassword: response,
              })
            );
          } else throw response;
        },
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  @Selector()
  static getResetLinkValidityResponse(state: AuthEmailStateModel) {
    return state.resetPasswordLinkValidity;
  }

  @Action(ClearResetLinkValid)
  ClearResetLinkValidity({ setState }: StateContext<AuthEmailStateModel>) {
    setState(
      patch({
        resetPasswordLinkValidity: undefined,
      })
    );
  }

  @Action(CheckResetLinkValid)
  CheckResetLinkValidity(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: CheckResetLinkValid
  ) {
    let request = payload;
    return this._authEmailService.checkResetLinkValidity(request).pipe(
      tap(
        (response: any) => {
          if (response && response.status) {
            setState(
              patch({
                resetPasswordLinkValidity: response,
              })
            );
          } else throw response;
        },
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  @Selector()
  static getResetPasswordResponse(state: AuthEmailStateModel) {
    return state.resetPassword;
  }

  @Action(ClearResetPasswordResponse)
  ClearResetPassword({ setState }: StateContext<AuthEmailStateModel>) {
    setState(
      patch({
        resetPassword: undefined,
      })
    );
  }

  @Selector()
  static getUserAvailabilityResponse(state: AuthEmailStateModel) {
    return state.userAvailabilitiy;
  }

  @Action(checkUserAvailability)
  GetUserAvailability(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: checkUserAvailability
  ) {
    let request = payload;
    return this._authEmailService.checkUserExists(request).pipe(
      tap(
        (response: any) => {
          if (response) {
            setState(
              patch({
                userAvailabilitiy: response,
              })
            );
          } else throw response;
        },
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  @Action(ResetPassword)
  ResetPassword(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: ResetPassword
  ) {
    let request = payload;
    return this._authEmailService.resetPassword(request).pipe(
      tap(
        (response: any) => {
          if (response && response.status) {
            setState(
              patch({
                resetPassword: response,
              })
            );
          } else throw response;
        },
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  @Action(UpdateUserProfileData)
  UpdateUserProfileData(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: UpdateUserProfileData
  ) {
    let request = payload;
    request['organizationId'] = Config.organizationId;
    const user = this.store.selectSnapshot(AuthEmailState.getUserDetails);
    let userId = user.user_id;
    return this._authEmailService.updateProfileData(request, userId).pipe(
      tap(
        (response: any) => {
          if (response && response.status) {
            let loginData = this.store.selectSnapshot(
              AuthEmailState.getFullLoginDetails
            );
            if (response.status == 200) {
              loginData.userData.primaryPhone = payload.phone;
              loginData.userData.fname = payload.fname;
              loginData.userData.lname = payload.lname;
              loginData.userData.dateOfBirth = payload.dateOfBirth;
              loginData.userInfo.primaryPhone = payload.phone;
              loginData.userInfo.fname = payload.fname;
              loginData.userInfo.lname = payload.lname;
              loginData.userInfo.dateOfBirth = payload.dateOfBirth;
              const loggedIn = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
              loggedIn.userName = `${payload.fname} ${payload.lname}`;
            }
            let customer = this.store.selectSnapshot(
              AuthState.getCommonAuthDetails
            );
            let detailsForCommonAuth: Auth = customer;
            if (response.status == 200) {
              detailsForCommonAuth.primaryPhone = payload.phone;
              detailsForCommonAuth.userName = `${payload.fname} ${payload.lname}`;
              this.store.dispatch(
                new SetCommonAuthDetails(detailsForCommonAuth)
              );
            }
            setState(
              patch({
                updateUserProfile: response,
                login: loginData,
              })
            );
          } else throw response;
        },
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  @Action(setLoggedInUserDetails)
  setLoggedInUserDetails(
    { setState }: StateContext<AuthEmailStateModel>,
    { payload }: setLoggedInUserDetails
  ) {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const detailsForCommonAuth: Auth = {
      isLoggedIn: true,
      userName: payload.username
        ? payload.username
        : payload.fname + ' ' + payload.lname,
      loginProvider: 'EMAIL',
      imgUrl: undefined,
      cartId: payload.cart_id ? payload.cart_id : undefined,
      primaryPhone: payload.primaryPhone ? payload.primaryPhone : undefined,
      customerId: payload.user_id,
      agreeToTerms: true,
      customerRegistryPhoneNumber : undefined
    };
    this.store.dispatch(new SetCommonAuthDetails(detailsForCommonAuth));
    const resp = {
      status: payload.status ? payload.status : 200,
      userData: payload,
      userInfo: payload,
      cartId: payload.cart_id ? payload.cart_id : undefined,
      locationId: payload.location_id ? payload.location_id : undefined,
      username: payload.username,
      isLoggedIn: payload ? true : false,
      deliveryAddress: payload.deliveryAddress ? payload.deliveryAddress : [],
      ordertype: selectedDelivery ? selectedDelivery.textCode : undefined,
      ordertypeId: selectedDelivery ? selectedDelivery._id : undefined,
    };
    setState(
      patch({
        login: resp,
      })
    );
  }

  defaultsForLogin() {
    return {
      grant_type: 'password',
      scopes:
        'SCP_ORGANIZATION SCP_USER SCP_WEBACCESS SCP_EDIT_ORG SCP_EDIT_ORG_LOCATION SCP_MANAGE_USER SCP_ORG_ACCESS SCP_NOT_ADMIN',
    };
  }

  @Action(DeleteProfile)
  DeleteProfile(
    { getState }: StateContext<AuthEmailStateModel>) {
    console.log('call profile delete');
    // const userId = payload;
    const userId = getState().customerId || getState().login.userInfo.user_id;
    const selectedLocation = this.store.selectSnapshot(app => app.location.selectedLocation);
    (selectedLocation?._id) ? this.router.navigate([`/locations/${selectedLocation._id}/home`]) : this.router.navigate([`/locations`]);;
    return this._authEmailService.deleteUser(userId).pipe(tap( response => {
      console.log('response from delete profile action',response);
      this.store.dispatch(new ClearLoginDetails());
      this.store.dispatch(new ClearCart());
    }))
  }
}
