<div
  class="navbar"
  id="myNavbar"
  [ngStyle]="hideProfile && { 'grid-template-columns': 'auto auto auto' }"
>
  <!-- <div
    *ngFor="let page of pageList"
    class="nav-item"
    (click)="routeTo(page.name)"
    [ngClass]="{ disable: page.id === 3 }"
  >
    <img [src]="setMenuIcon(page)" [ngClass]="{ profileicon: page.name =='profile' && loggedInUserDetails && loggedInUserDetails.provider }"
    alt="menu icon"/>
    <div class="nav-text" [ngClass]="{ active: selectedPage == page.name }">
      {{ page.name | translate }}
    </div>
  </div> -->
  <div
    class="nav-item"
    (click)="routeTo('home')"
    data-qa="home"
    [class.active]="selectedPage == 'home'"
    attr.aria-label="{{ 'home' | translate }}"
  >
    <div class="icon home-icon"></div>
    <div class="nav-text">{{ 'home' | translate }}</div>
  </div>
  <div
    class="nav-item"
    (click)="routeTo('menu')"
    data-qa="menu"
    [class.active]="selectedPage == 'menu'"
    attr.aria-label="{{ 'menu' | translate }}"
  > <div class="numberCircle" *ngIf="cartCount">{{cartCount}}</div>
    <div class="icon menu-icon"></div>
    <div class="nav-text">{{ 'menu' | translate }}</div>
  </div>
  <div
    class="nav-item"
    (click)="routeTo('deals')"
    [class.active]="selectedPage == 'deals'"
    attr.aria-label="{{ 'deals' | translate }}"
    *ngIf="enableCoupon"
  >
    <div class="icon deals-icon"></div>
    <div class="nav-text">{{ 'deals' | translate }}</div>
  </div>
  <div *ngIf="!hideOrders"
    class="nav-item"
    (click)="routeTo('view-orders')"
    data-qa="orders"
    [class.active]="selectedPage == 'view-orders'"
    attr.aria-label="{{ 'orders' | translate }}"
  >
    <div class="icon order-icon"></div>
    <div class="nav-text">{{ 'orders' | translate }}</div>
  </div>
  <div
    class="nav-item"
    (click)="routeTo('profile')"
    data-qa="profile"
    [class.active]="selectedPage == 'user-profile'"
    *ngIf="!hideProfile"
    attr.aria-label="{{ 'profile' | translate }}"
  >
    <div class="icon profile-icon"></div>
    <div class="nav-text">{{ 'profile' | translate }}</div>
  </div>
</div>
