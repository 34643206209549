import {
  BaseComponent,
  environment,
  FetchLocationList,
  LocationState,
  Location,
  ChangeMapPopupStatus,
  ChangeadrsDetailsPopupStatus,
  SweetAlertService,
  WhitelabelState,
  AuthState,  
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Output, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import {
  SetSelectedLocation,
  FetchLocationInfo,
  ClearSelectedLocation,
  FetchLocationDefaults,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Router } from '@angular/router';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { Plugins } from '@capacitor/core';
// import { CustomerFavouritesStateModel } from '@moduurnv2/libs-orderingapp/src/core/state/customer-favourites.state';
const { Geolocation } = Plugins;

export class OrganizationLocationsBaseComponent extends BaseComponent
  implements OnInit {
  geocoder: MapboxGeocoder;
  @Output() closeEvent = new EventEmitter();
  organizationLocations: Location[] = [];
  locations: Location[];
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  seletedLocatioId: string = undefined;
  selectedLocation: string = undefined;
  locationPinIcon: string =
    environment.image_basepath + environment.images.location_pin;
  logoIcon: string = environment.image_basepath + environment.images.logo_big;
  closeIcon: string = environment.image_basepath + environment.images.close;
  getDirections: string =
    environment.image_basepath + environment.images.get_directions_icon;
  getDirection: string =
    environment.image_basepath + environment.images.get_direction_icon;
  phone: string = environment.image_basepath + environment.images.phone;
  isRestaurantsAvailable: boolean = true;
  isLocationOn: boolean = true;
  getDirectionDesktop: string =
    environment.image_basepath + environment.images.get_direction_desktop_icon;
  listLimit: number = 3;

  locationSearchQuery:string='';
  searchClose : string = environment.image_basepath + environment.images.desktop_modal_close;
  locationSearchPlaceholder : string = this.translate.instant('location-search-placeholder');
  yepDeskEvents:boolean;
  viewEvents:boolean = false;
  showLocationImage : boolean = false;
  imageBaseUrl: string  =environment.imagebaseurl;
  favouriteLocationView: boolean = false;
  locationview: boolean = true;
  selectedTab: string = "LOCATION";
  constructor(
    public router: Router,
    public sharedService: SharedService,
    public store: Store,
    public route: ActivatedRoute,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService,
    public httpresult: HttpClient,
    public modal:ModalService
  ) {
    super();
    //this.store.dispatch(new FetchLocationList());
    this.getlocation();
    this.showLocationImage  = this.showLocationImageFromConfig();
    let isInitialLoad = false;
    this.locationsList$.subscribe(async (response) => {
      this.organizationLocations = this.locations = response;
   
      // this.organizationLocations = this.locations = await this.sortLocationByDistance(response);
      
      this.organizationLocations?.forEach(orgLoc => orgLoc['iconLoading'] = false)
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);     
      if(customer){
        this.favouriteLocationView = this.locations?.some(locList => locList.isFavorited);
        this.selectedTab = this.favouriteLocationView ? this.selectedTab : 'LOCATION';
        this.organizationLocations = this.locations?.filter(filtArr => this.selectedTab == 'LOCATION' ? true : filtArr.isFavorited);        
      }else{
        this.favouriteLocationView = false;
        this.organizationLocations = this.locations;
      }

      //Sorting Location's List by location access approved in browser.
      if(this.isLocationOn){
        this.organizationLocations = await this.sortLocationByDistance(this.organizationLocations);
      }else{
        this.organizationLocations = this.organizationLocations?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));;
      }
      
      const selectedLocation = this.store.selectSnapshot(
        LocationState.getSelectedLocation
      );
      if (
        !selectedLocation &&
        !this.selectedLocation &&
        this.organizationLocations &&
        this.organizationLocations[0] &&
        this.organizationLocations[0]._id
      ) {
        // COMMENTING AS PART OF mv2-1012(Removing default location selection on load)
        // this.selectLocation(this.organizationLocations[0]);
      } else if (selectedLocation && selectedLocation._id) {
        // this.seletedLocatioId = selectedLocation._id;
        if (this.locations && this.locations.length) {
          let index = this.locations.findIndex(
            (item) => item._id == selectedLocation._id
          );
          if (index != -1) {
            if(!isInitialLoad){
              this.selectLocation(this.locations[index]);
              this.store.dispatch(
                new SetSelectedLocation(this.locations[index]._id)
              );
            }
            isInitialLoad = true;
          } else {
            if (this.selectedLocation) {
              this.showLocationMissing();
            }
          }
        }
      }
      
    });
  }
  openMap() {
    this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
    this.store.dispatch(new ChangeMapPopupStatus(true));
  }
  closeMap() {
    this.store.dispatch(new ChangeMapPopupStatus(false));
  }

  ngOnInit() {
    let WhitelabelOther = this.store.selectSnapshot(WhitelabelState.getOther)
    console.log(WhitelabelOther.eventTicketing)
    if(WhitelabelOther.eventTicketing.isAllow == true && WhitelabelOther.eventTicketing.keyId && WhitelabelOther.eventTicketing.keySecret ){
      this.yepDeskEvents = true;
  }

  // let favouriteDetails = this.store.selectSnapshot(CustomerFavouritesStateModel.getCustomerFavouritesList);   

    // if (this.sharedService.isMobileScreen()) this.listLimit = 2;
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation && selectedLocation._id) {
      this.seletedLocatioId = selectedLocation._id;
    }
    // this.store.dispatch(new FetchLocationList());
    this.subscribeLocations();

    Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(
      environment.mapbox.accessToken
    );
    this.geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: this.translate.instant('loc-search-placeholder'),
    });
    let isRefinedLocation = localStorage.getItem('refinedLocation')
      ? JSON.parse(localStorage.getItem('refinedLocation'))
      : undefined;
    // this.geocoder.addTo('#geocoder');
    try{
    this.geocoder.addTo('#geocoder');
    }catch{
      // left intentionally blank
    }
    if (isRefinedLocation != undefined) {
      this.locationDetails(
        isRefinedLocation.coordinates[0],
        isRefinedLocation.coordinates[1]
      );
    }
    try{
    this.geocoder.on('result', (results) => {
      this.isLocationOn = true;
      this.store.dispatch(
        new FetchLocationList(results.result.geometry.coordinates)
      );
      let refinedLocation = {
        placeName: results.result.place_name,
        coordinates: results.result.geometry.coordinates,
        text: results.result.text,
      };
      localStorage.setItem('refinedLocation', JSON.stringify(refinedLocation));
    });
    }catch{
      // left intentionally blank
    }
  }
  subscribeLocations() {
    this.locationsList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locations) => {
        this.isRestaurantsAvailable = true;
        if(locations){
        if (locations && locations.length == 1) {
          this.router.navigate([`locations/${locations[0]._id}/home`]);
          this.store.dispatch(new SetSelectedLocation(locations[0]._id));
          this.store.dispatch(new FetchLocationDefaults());
        }
        this.setRestaurantsExistResult(locations);
        }
      });
  }
  closeOrganizationLocation() {    
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation && selectedLocation._id) {
      this.seletedLocatioId = selectedLocation._id;
      this.router.navigate([`locations/${this.seletedLocatioId}/home`]);
    } else {
      this.router.navigate([`/locations`])
    }
  }

  async tabClick(tabSection?) {
    this.locationview = true;
    this.selectedTab = tabSection;
    // if (tabSection == 'FAVORITE') {
    //   this.organizationLocations = this.locations.filter(
    //     (loc) => loc.isFavorited == true
    //   );
    // } else {
    //   this.organizationLocations = this.locations;
    // }  
 
    let filteredLoc = this.locations.filter((loc) => tabSection == 'FAVORITE' ? loc.isFavorited : true );    
    // this.organizationLocations = filteredLoc.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.organizationLocations = this.isLocationOn ? await this.sortLocationByDistance(filteredLoc) : filteredLoc.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  }
  getlocation() {
      Geolocation.getCurrentPosition()
      .then((position) => {
        if (position) {
          this.isLocationOn = true;
          let isRefinedLocation = localStorage.getItem('refinedLocation')
            ? JSON.parse(localStorage.getItem('refinedLocation'))
            : undefined;
          if (isRefinedLocation) {
            let deliveryLocation =
              localStorage.getItem('selectedAdrsLocation') &&
              localStorage.getItem('selectedAdrsLocation') != 'undefined'
                ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
                : undefined;
            if (deliveryLocation) {
              this.store.dispatch(
                new FetchLocationList(deliveryLocation['loc'])
              );
            } else {
              this.store.dispatch(
                new FetchLocationList(isRefinedLocation.coordinates)
              );
            }
          } else {
            this.locationDetails(
              position.coords.longitude,
              position.coords.latitude
            );
            this.store.dispatch(
              new FetchLocationList([
                String(position.coords.latitude),
                String(position.coords.longitude),
              ])
            );
          }
        }
      })
      .catch((error) => {
        this.isLocationOn = false;
        let isRefinedLocation = localStorage.getItem('refinedLocation')
          ? JSON.parse(localStorage.getItem('refinedLocation'))
          : undefined;
        if (isRefinedLocation) {
          this.store.dispatch(
            new FetchLocationList(isRefinedLocation.coordinates)
          );
        } else this.store.dispatch(new FetchLocationList());
      })
  }
  locationDetails(lng, lat) {
    var url =
      environment.mapbox.reverseDecodingURL +
      lng +
      `,` +
      lat +
      `.json?types=poi&access_token=` +
      environment.mapbox.accessToken;
    this.httpresult.get(url).subscribe((data) => {
      if (data['features'] && data['features'].length) {
        let result = data['features'][0];
        this.geocoder.query(result['place_name']);
        let refinedLocation = {
          placeName: result.place_name,
          coordinates: result.geometry.coordinates,
          text: result.text,
        };
        localStorage.setItem(
          'refinedLocation',
          JSON.stringify(refinedLocation)
        );
      }
    });
  }
  selectLocation(location) {
    this.viewEvents = false;
    this.selectedLocation = location;
    // this.store.dispatch(new SetSelectedLocation(location._id));
    this.store.dispatch(new FetchLocationInfo(location._id));
  }
  showLocationMissing() {
    this.sweetAlert
      .confirmWithoutCancel(
        '',
        'Your selected location is closed now.Please select another location'
      )
      .then((result) => {
        this.store.dispatch(new ClearSelectedLocation());
        this.selectedLocation = null;
        if (
          this.organizationLocations[0] &&
          this.organizationLocations[0]._id
        ) {
          this.selectLocation(this.organizationLocations[0]);
        }
        // this.getlocation();
      });
  }

  filterLocationByQuery(){
    let filterdLocationList =  this.locations.length ? this.locations.filter(filtArr => this.selectedTab == 'FAVORITE' ? filtArr.isFavorited : true) : this.locations; 
    if(this.locationSearchQuery && this.locations){          
    this.organizationLocations = filterdLocationList.filter(loc=>      
      loc.name.toUpperCase().includes(this.locationSearchQuery.toUpperCase()) ||
      loc.name.replace(/\s/g, '').toUpperCase().includes(this.locationSearchQuery.replace(/\s/g, '').toUpperCase()) ||
      loc.address.toUpperCase().includes(this.locationSearchQuery.toUpperCase()) ||
      loc.address.toUpperCase().includes(this.locationSearchQuery.replace(/\s/g, '').toUpperCase()) ||
      loc.address.replace(/\s/g, '').toUpperCase().includes(this.locationSearchQuery.toUpperCase())||
      loc.address.replace(/\s/g, '').toUpperCase().includes(this.locationSearchQuery.replace(/\s/g, '').toUpperCase())
    )
    }
    else
    this.organizationLocations = filterdLocationList;
    this.setRestaurantsExistResult(this.organizationLocations);
  }

  async clearlocationSearch(){
    let filterdLocationList =  this.locations.length ? this.locations.filter(filtArr => this.selectedTab == 'FAVORITE' ? filtArr.isFavorited : true) : this.locations; 
    this.organizationLocations = await this.sortLocationByDistance(filterdLocationList) ;
    this.locationSearchQuery='';
    this.setRestaurantsExistResult(this.organizationLocations);
  }

  setRestaurantsExistResult(filteredLocations){
    if(filteredLocations && filteredLocations.length>0)
    this.isRestaurantsAvailable = true;
    else
    this.isRestaurantsAvailable = false;
  }


  sortLocationByDistance(locations):Promise<Location[]>{
   
    return new Promise(async (resolve) => {
        Geolocation.getCurrentPosition()
          .then((position) => {
            if (position.coords.latitude && position.coords.longitude) {
              let customerCoords = [
                position.coords.longitude,
                position.coords.latitude,
              ];
              for (let i = 0; i < locations.length; i++) {
                locations[i].distanceFromCustomer = this.calculateDistanceBetweenPoints(customerCoords,locations[i].loc);
              }
            }
          
            locations = locations.sort(function (a, b) {
              return a.distanceFromCustomer - b.distanceFromCustomer;
            });
            resolve(locations);
          })
          .catch( error => {
            this.isLocationOn = false;
          })
        resolve(locations);
      resolve(locations);
    });
  }

  calculateDistanceBetweenPoints(posA, posB) {
      const earthRadius = 6371;
      let latitutde = posB[1] - posA[1]; // Difference of latitude
      let longitude = posB[0] - posA[0]; // Difference of longitude
      let disLat = (latitutde * Math.PI * earthRadius) / 180; // Vertical distance
      let disLon = (longitude * Math.PI * earthRadius) / 180; // Horizontal distance
      let result = Math.pow(disLat, 2) + Math.pow(disLon, 2);
      result = Math.sqrt(result); // Total distance (calculated by Pythagore: a^2 + b^2 = c^2)
      // console.log(result);
      return result;
  } 
  showEvents(){
    this.viewEvents = true;
  }

  showLocationImageFromConfig (): boolean {
    const whitelabelSettings = this.store.selectSnapshot(WhitelabelState.getWhitelabel)
    return whitelabelSettings?.commonSettings?.showLocationImages ? whitelabelSettings.commonSettings.showLocationImages : false;
  }

  skeletalCounter(i: number) {
    return new Array(i);
  }
 
}

