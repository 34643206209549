import {
  BaseComponent,
  OrderState,
  LocationDefaultState,
  LocationDefaults,
  FetchLocationDefaults,
  PaymentMethodState,
  WhitelabelState,
  Whitelabel,
  environment,
  CartState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import {
  // Cart,
  // CartState,
  LocationState,
  Location,
  SetSelectedOrderId,
  // ClearCart,
  SetInprogressOrderIds,
  SelectedOrderState,
  AuthState,
  ClearOrderResponseMessage,
  SharedService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export class OrderSuccessBaseComponent extends BaseComponent {
  bannerImg = 'assets/images/Berries.png';
  successImg = 'assets/images/Success-Icon-Big.svg';
  successImgThumbs = 'assets/images/Success Icon Big Thumbs.svg';
  infoIcon = 'assets/images/Info.svg';
  closeIcon = 'assets/images/Close-white.svg';
  receiptIcon = 'assets/images/receipt_icon_shaded.svg';
  rateIcon = 'assets/images/Star.svg';
  headerText = 'order-successful';

  estimatedTime: number;

  // @Select(CartState.getCart) cart$: Observable<Cart>;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  @Select(PaymentMethodState.getSelectedPaymentType) paymentType$: Observable<
    string
  >;
  @Select(WhitelabelState.getWhitelabel) whiteLabel$: Observable<Whitelabel>;
  @Select(CartState.getETA) eta$: Observable<any>;
  // cart: Cart;
  orderDetails: any;
  successScreen: any;
  overRide: boolean;
  allowFeedback: boolean;
  feedBackUrl: string;
  feedbackMessage: string;
  location: Location;
  locationDefaults: LocationDefaults;
  paymentType: string;
  orderSettings;
  hideReceipt: boolean;
  hideOrder: boolean;
  constructor(
    public router: Router,
    public store: Store,
    public modal: ModalService,
    public shared: SharedService,
    private translate : TranslateService
  ) {
    super();
    this.orderDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((orderDetails) => {
        this.orderDetails = orderDetails;
      });
     
    this.whiteLabel$.pipe(takeUntil(this.destroy$)).subscribe((whiteLabel) => {
      this.successScreen = whiteLabel.successScreen;
      if (whiteLabel?.successPage?.phone) {
        this.bannerImg =
          environment.imagebaseurl + whiteLabel.successPage.phone;
      }
      if (this.successScreen && this.successScreen.isOverride) {
        this.successImg = environment.imagebaseurl + this.successScreen.icon;
        if(this.successScreen.headerBanner){
          this.bannerImg =
          environment.imagebaseurl + this.successScreen.headerBanner;
        }
          if(this.successScreen.heading.length){
            this.headerText = this.successScreen.heading;
          }
        this.overRide = this.successScreen.isOverride;
        if (this.successScreen.feedbackLink) {
          this.allowFeedback = this.successScreen.feedbackLink.isAllow;
          this.feedBackUrl = this.successScreen.feedbackLink.url;
          this.feedbackMessage = this.successScreen.feedbackLink.feedbackMessage;
        }
      }
     
      if (whiteLabel.commonSettings) {
        this.hideReceipt = whiteLabel.commonSettings.hideReceipt;
        this.hideOrder = whiteLabel.commonSettings.hideOrderRelatedScreens;
      }
      if ( this.successScreen.icon && this.overRide){
        this.successImgThumbs = environment.imagebaseurl + this.successScreen.icon;
      }
    });
    // this.cart$.pipe(takeUntil(this.destroy$)).subscribe((cart) => {
    //   if (cart) {
    //     this.cart = cart;
    //   }
    // });
    this.location$.pipe(takeUntil(this.destroy$)).subscribe((location) => {
      if (location) {
        this.location = location;
      }
    });
    this.locationDefaults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationDefaults) => {
        if (locationDefaults) {
          this.locationDefaults = locationDefaults;
          this.orderSettings = locationDefaults.locationData.orderSettings;
        }
      });
    this.paymentType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentType) => {
        if (paymentType) {
          this.paymentType = paymentType;
        }
      });
    this.store.dispatch(new FetchLocationDefaults());
    this.setDataForOrderInprogress();
    this.getOrderDetails();
  }

  //Changes for MV2-166
  // Use Guards instead of this
  ngAfterContentChecked() {
    // if(!this.cart){
    //   this.router.navigate([`/locations/${this.location._id}/home`]);
    // }
  }

  getOrderDetails() {
    let orderSettings = this.locationDefaults.locationData.orderSettings;
    if (this.orderDetails && !this.orderDetails.futureOrder) {
      this.orderDetails.deliveryTime = orderSettings.maxPrepTime;
    }
  }

  close() {
    this.router.navigate([`/locations/${this.location._id}/menu`]);
  }

  viewStatus() {
    const { order_id } = this.store.selectSnapshot(
      OrderState.getOrderResponse
    ) || { order_id: undefined };
    if (order_id) {
      this.store.dispatch(new SetSelectedOrderId(order_id));
      this.store.dispatch(new ClearOrderResponseMessage());

      this.router.navigate([`/locations/${this.location._id}/order-details`]);
    } else {
      let selectedOrderIdToView = this.store.selectSnapshot(
        SelectedOrderState.getSelectedOrderId
      ) || { selectedOrderIdToView: undefined };
      if (selectedOrderIdToView) {
        this.store.dispatch(
          new SetSelectedOrderId(selectedOrderIdToView.toString())
        );
        this.router.navigate([`/locations/${this.location._id}/order-details`]);
      }
    }
  }

  startOrder() {
    if (this.location._id) {
      this.router.navigate([`/locations/${this.location._id}/menu`]);
    }
  }

  viewReceipt() {
    const { order_id } = this.store.selectSnapshot(
      OrderState.getOrderResponse
    ) || { order_id: undefined };
    if (order_id) {
      this.store.dispatch(new SetSelectedOrderId(order_id));
      this.store.dispatch(new ClearOrderResponseMessage());
      // this.router.navigate([`/order-receipt`]);
    } else {
      const selectedOrderIdToView = this.store.selectSnapshot(
        SelectedOrderState.getSelectedOrderId
      ) || { selectedOrderIdToView: undefined };
      if (selectedOrderIdToView) {
        this.store.dispatch(
          new SetSelectedOrderId(selectedOrderIdToView.toString())
        );
      }
    }
  }

  setDataForOrderInprogress() {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (!customer || customer.isLoggedIn == false) {
      // const { order_id } = this.store.selectSnapshot(
      //   OrderState.getOrderResponse
      // ) || { order_id: undefined };
      const selectedOrderIdToView = this.store.selectSnapshot(
        SelectedOrderState.getSelectedOrderId
      ) || { selectedOrderIdToView: undefined };
      const ids = this.store.selectSnapshot(
        SelectedOrderState.getInprogressOrderIds
      );
      let orderIds = [];
      if (ids && ids.length > 0 && selectedOrderIdToView) {
        orderIds = ids;
        const foundId = ids.find( id => id === selectedOrderIdToView );
        if(!foundId)
          orderIds.push(selectedOrderIdToView);
      } else if (selectedOrderIdToView) {
        orderIds = [selectedOrderIdToView];
      }
      this.store.dispatch(new SetInprogressOrderIds(orderIds));
    }
  }

  formatTime(futureDate) {
    let timeFormat = 'hh:mm A';
    if(this.translate && this.translate.currentLang == 'fr'){
      timeFormat = 'HH:mm';
    }
    if(futureDate && this.orderDetails?.futureEndDate){
      return this.displayUpdatedTime(futureDate,this.orderDetails.futureEndDate,timeFormat)
    } else {
    const futureTime = moment(futureDate);
    let timeText = futureTime.format('MMM DD ' + timeFormat);
    const usePicker = this.store.selectSnapshot(LocationState.getTimePicker);
    const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
    const timeSlot = locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.isAllow ? 
                        locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.timeSlot : 30;
  
    if (usePicker === false)
      timeText += ` - ${futureTime.add({ minutes: timeSlot }).format(timeFormat)}`;
    return timeText;
    }
  }

  displayUpdatedTime(futureStartDate,futureEndDate,timeFormat) {
    if (futureStartDate && futureEndDate) {
      let timeText = moment(futureStartDate).format('MMM DD ' + timeFormat);
      const usePicker = this.store.selectSnapshot(LocationState.getTimePicker);
      if (usePicker === false)
      timeText += ` - ${moment(futureEndDate).format(timeFormat)}`;
      return timeText;
    }
  }

  getASAPDisplayTime(eta) {
    if(eta?.maxPrepTime) {
      return (eta.maxPrepTime)
    } else if(this.orderDetails?.deliveryTime != undefined) {
      return (this.orderDetails.deliveryTime)
    } else {
      return ''
    }
  }
}
