import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Category, PromotionalCategory } from '../models';
import { Injectable } from '@angular/core';
import { HomeService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { FetchHomeData, ReOrderItems } from '../actions/home.actions';
import { LocationState } from './location.state';
import { DeliveryState } from './delivery-methods.state';
import { throwError } from 'rxjs';
import { AuthState } from './auth.state';

export class HomeStateModel {
  category: Category;
  promotionalCategory: PromotionalCategory;
  reorderItems: [];
}

@State<HomeStateModel>({
  name: 'home',
  defaults: {
    category: {
      _id: '',
      name: '',
      description: '',
      items: undefined,
      translation: '',
    },
    promotionalCategory: { _id: '', name: '', items: undefined },
    reorderItems: [],
  },
})
@Injectable()
export class HomeState {
  constructor(private _home: HomeService, private store: Store) {}

  @Selector()
  static getHomeData(state: HomeStateModel) {
    return state;
  }

  @Selector()
  static getHomeCategories(state: HomeStateModel) {
    return state.category;
  }

  @Selector()
  static getHomePromotionalCategory(state: HomeStateModel) {
    return state.promotionalCategory;
  }

  @Selector()
  static getReorderItems(state: HomeStateModel) {
    return state.reorderItems;
  }

  @Action(FetchHomeData)
  fetchHomeData({ setState }: StateContext<HomeStateModel>) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let selectedLocId;
    let selectedDeliveryCode;

    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    if (selectedDelivery && selectedDelivery.textCode)
      selectedDeliveryCode = selectedDelivery.textCode;
    else if (
      selectedLocation &&
      selectedLocation['deliveryTypes'] &&
      selectedLocation['deliveryTypes'].length
    )
      selectedDeliveryCode = selectedLocation['deliveryTypes'][0].textCode;

    if (selectedLocation && selectedLocation._id) {
      selectedLocId = selectedLocation._id;
    }
    return this._home.fetchHomeData(selectedLocId, selectedDeliveryCode).pipe(
      tap((response) => {
        if (response && response.data) setState(patch(response.data));
        else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(ReOrderItems)
  reOrderItems({ patchState }: StateContext<HomeStateModel>, { payload }: any) {
    
    patchState({
      reorderItems: null
    });

    const selectedLocation = this.store.selectSnapshot( LocationState.getSelectedLocation );
    const selectedDelivery = this.store.selectSnapshot( DeliveryState.getSelectedDelivery );
    const loggedInUser = this.store.selectSnapshot( AuthState.getCommonAuthDetails );
    
    const isCustomerRegistry = selectedLocation && selectedLocation.orderSettings && selectedLocation.orderSettings.isAllowGlobalCustomerRegistry
    //if(!selectedLocation || !selectedDelivery || !loggedInUser || (loggedInUser?.isLoggedIn == false && !isCustomerRegistry))
    //  return;

    const request: {
      locationId: string,
      deliveryId: number,
      deliveryMethod: string,
      customerId?: string,
      orderIds?: string[],
      fetchCount: number,
      customerPhoneNumber?: string,
    } =  { 
      locationId : selectedLocation._id,
      deliveryId: selectedDelivery._id,
      deliveryMethod: selectedDelivery.textCode,
      fetchCount: payload || 1,
    }
    
        if (loggedInUser?.customerId)
        request.customerId = loggedInUser.customerId;
    
        if (loggedInUser?.primaryPhone)
        request.customerPhoneNumber = loggedInUser.primaryPhone;
        
        if (loggedInUser?.customerRegistryPhoneNumber)
        request.customerPhoneNumber = loggedInUser.customerRegistryPhoneNumber;
    
    return this._home
      .fetchReOrderItems(request)
      .pipe(
        tap((response) => {
          if(response && response.orders)
            patchState({
              reorderItems: response.orders
            });
          else {
            patchState({
              reorderItems: []
            });
            throw response;
          }
        }),
        catchError((error) => {
          patchState({
            reorderItems: []
          });
          return throwError(error);
        })
      );
  }
}
