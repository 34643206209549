import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import {
  NotificationBarBaseComponent,
  MenuItemCreation,
  CartService,
  CartState,
  Cart,
  CartItems,
  LocationState,
  LocationStatus,
  DeliveryState,
  Delivery,
  ClearCart,
  ClearSelectedAddress,
  ClearDeals,
  ClearSelectedLocation,
  SweetAlertService,
  AnalyticsTrackingType,
  ItemCheckoutAnalytics,
  setAnalyticsData,
  Items,
  TypeOfPages,
  LocationDefaultState,
  LocationDefaults,
  OrderLevelModifierState,
  FetchOrderLevelModifiers
} from '@moduurnv2/libs-orderingapp';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common'
import { AnalyticsHandler } from '@moduurnv2/core/services';
@Component({
  selector: 'moduurnv2-notification-bar',
  templateUrl: 'notification-bar.component.html',
  styleUrls: ['notification-bar.component.scss'],
})
export class NotificationBarComponent extends NotificationBarBaseComponent
  implements OnInit {
  @Input('menuitem') menuItem: any[];
  @Output() viewReview = new EventEmitter();

  selectedMenuItem: MenuItemCreation[];
  @Select(CartState.getCart) cart$: Observable<Cart>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  @Select(LocationState.getLocationStatus) locationStatus$: Observable<
    LocationStatus
  >;
  @Select(DeliveryState.getSelectedDelivery) delivery$: Observable<Delivery>;
  
  @Select(LocationDefaultState.getLocationDefaults) locationDefaults$: Observable<LocationDefaults>;
  @Select(OrderLevelModifierState.getModifiers) getOrderLevelModifiers$: Observable<any>;
  @Select(CartState.getFutureDate) futureDate$: Observable<[]>;
  value: number;
  isEmpty: boolean;
  virtual: boolean;
  locCloseMsg: string;
  myCart: any;
  // locationStatus: LocationStatus;
  delivery: Delivery;
  location;
  futureDate;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    // appFontFamily: true,
  };
  currentPage : string;
  continueBtnText : string = this.translate.instant('Checkout');
  orderSettings: any;
  orderLevelModifiers:any;
  locationOrderingBlocked: boolean;
  constructor(public cartstate: CartState, store: Store,  public sweetAlert: SweetAlertService, public router: Router,private translate : TranslateService,public datepipe: DatePipe,public analyticsHandler: AnalyticsHandler) {
    super(store);
    this.isEmpty = true;
    this.value = 0;
    this.cart$.subscribe((response) => {
      this.myCart = response;
    });
    this.delivery$.pipe(takeUntil(this.destroy$)).subscribe((delivery) => {
      if (delivery) {
        this.delivery = delivery;
      }
    });
    this.currentPage = this.router.url.includes('menu') ? TypeOfPages.MENUPAGE : TypeOfPages.ORDERLEVELMODIFIERPAGE;
    this.locationDefaults$
    .pipe(takeUntil(this.destroy$))
    .subscribe((locationDefaults) => {
      if (locationDefaults) {
        this.location = locationDefaults;
        this.orderSettings = locationDefaults.locationData.orderSettings;
      }
    });
    this.futureDate$.subscribe((future) => {
      this.futureDate = future;
    });
  }

  ngOnInit() {
    this.getLocationUpdate();
    this.checkLocationStatus();
    this.fetchOrderLevelModifiers();
    this.getOrderLevelModifiers$.pipe(takeUntil(this.destroy$)).subscribe((orderLevelModifiers) => {
      this.orderLevelModifiers = orderLevelModifiers;
      this.showReviewPageAsNextPage();
    });
  }

  getLocationUpdate() {
    this.virtual = this.delivery.textCode == 'DELIVERYTYPE04' ? true : false;
    if (this.delivery.textCode == 'DELIVERYTYPE05') {
      this.locCloseMsg = 'You can place the order once the event starts';
    } else {
      if (this.delivery.textCode == 'DELIVERYTYPE02') {
        this.locCloseMsg = 'Ordering is closed';
      } else {
        this.locCloseMsg =
          'You can place the order only when the location is Open';
      }
    }
  }
  emitReviewEvent() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.trackAnalyticsItemCheckout(this.myCart);
    this.analyticsHandler.setEvents("Check-Out", "Ecommerce", "Check-Out",  selectedLocation._id, selectedLocation);
    const el: HTMLElement | null = document.getElementById('viewOrderBtn');
    if (el) this.viewReview.emit();
  }

  trackAnalyticsItemCheckout(cartDetails){
    if(location){
      const items: Items[] = [];
      for(let itemIndex=0; itemIndex<cartDetails.itemPrice.length; itemIndex++){
        let itemValues: Items = {
            id: cartDetails?.itemPrice[itemIndex]?._id, 
            itemName: cartDetails?.itemPrice[itemIndex]?.name,
            quantity:cartDetails?.itemPrice[itemIndex]?.count,
            item_price:cartDetails?.itemPrice[itemIndex]?.price
          }
            items.push(itemValues);
      }
      const itemCheckoutAnalytics: ItemCheckoutAnalytics = {
        items: items,
        analyticsTrackingType: AnalyticsTrackingType.ITEM_CHECKOUT, 
        totalPeriodAmount:cartDetails.price,
      };
    this.store.dispatch(new setAnalyticsData(itemCheckoutAnalytics));}
  }

  itemText(count) {
    return count > 1 ? ' Items' : 'Item';
  }

  checkLocationStatus() {
    this.locationStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationStatus) => {
        this.locationOrderingBlocked = this.blockLocationOrdering(locationStatus);
        if (locationStatus && locationStatus.isUnSubscribed) {
          this.store.dispatch(new ClearCart());
          this.store.dispatch(new ClearSelectedAddress());
          this.store.dispatch(new ClearDeals());
          this.showLocationMissing();
        }
      });
  }

  blockLocationOrdering(locationStatus){
    return (locationStatus &&
      (!locationStatus.isOpenToday || !locationStatus.isCurrentlyOpen) &&
      this.virtual == false)
  }
  showLocationMissing() {
    this.sweetAlert
      .confirmWithoutCancel(
        '',
        'Your selected location is closed now.Please select another location'
      )
      .then((result) => {
        this.store.dispatch(new ClearSelectedLocation());
        this.router.navigate([`/locations`]);
      });
  }

  proceedToNextStep(){
    if(!this.locationOrderingBlocked){
      if(this.showReviewPageAsNextPage()){
        this.openReviewPayModal();
      } else {
        this.openOrderLevelModifierPage();
      }
    }
  }

  showReviewPageAsNextPage(){
    let isReviewPageAsNextPage: boolean = true;
    if(this.currentPage == TypeOfPages.MENUPAGE && this.orderLevelModifiers?.length && this.orderSettings?.isAllowOrderLevelModifier){
      isReviewPageAsNextPage = false;
      this.continueBtnText = this.translate.instant('continue');
    }
    return isReviewPageAsNextPage;
  }
  

  openReviewPayModal(){
    if(!this.checkOrderLevelModifiers()){
      return this.sweetAlert.toast('error', 'Sorry, Please select the required items to continue.');
    }
    this.viewReview.emit();
  }

  openOrderLevelModifierPage() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/order-level-modifier`]);
    }
  }

  checkOrderLevelModifiers(){
    if(this.orderSettings?.isAllowOrderLevelModifier){
     let modifiers = this.store.selectSnapshot(OrderLevelModifierState.getModifiers);
     if(modifiers && modifiers.length){
       let isSelected = modifiers.every(function(element) {
         if(element && element.isForced){
           if(element.isInCart){
             return true;
           }
           else{
             return false;
           }
         }
         else
           return true;
       });
      return isSelected;
     }
     else{
       return true;
     }
    }
    else{
      return true;
    }
   }

   fetchOrderLevelModifiers = () => {
    const selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let data = {
      menuProfileId: selectedLocation.activeProfile._id,
      deliveryType: selectedDelivery._id,
    };
    this.store.dispatch(new FetchOrderLevelModifiers(data));
  };

  nextStoreOpenDayIsToday(){
    let nextStoreOpenDayIsToday:boolean;
    if(this.location && this.location.nextOpenTime){
      if((new Date(this.location.nextOpenTime).getDate() == new Date().getDate()) && (new Date(this.location.nextOpenTime).getMonth() == new Date().getMonth())){
         nextStoreOpenDayIsToday = true;
      } else {
        nextStoreOpenDayIsToday = false;
      }
    }
    return(nextStoreOpenDayIsToday)
   }
   displayClosedMessage(){
    let nextStoreOpenDayIsToday = this.nextStoreOpenDayIsToday();
    let message = "";
    if(!this.location?.isCurrentlyOpen){
       if(!this.futureDate && this.location.nextOpenTime){
         message ="Sorry, " + this.delivery.text + " is scheduled to begin at "
         if(nextStoreOpenDayIsToday){
           message = message +  this.datepipe.transform(this.location.nextOpenTime, 'hh:mm a')
         }
         else{
         message = message + this.datepipe.transform(this.location.nextOpenTime, "dd/MM/yyyy hh:mm a")
         }
       }
       else if(this.futureDate && this.delivery.textCode != 'DELIVERYTYPE05'){
         message = this.location.closeMessage

       }
       else{
         message = 'Ordering is closed';
       }
     }
     return(message)

    }
    testCheckout(){
      if(this.displayClosedMessage()==""){
        return true
      }
      else{
        return false
      }
    }
  
}
