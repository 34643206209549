import { Auth } from '../models';

export class SetCommonAuthDetails {
    static readonly type = '[AUTH] SetCommonAuthDetails';
    constructor(public payload: Auth) { }
}


export class FetchCommonAuthDetails {
    static readonly type = '[AUTH] FetchCommonAuthDetails';
    constructor() { }
}

export class SetCartId {
    static readonly type = '[AUTH] SetCartId';
    constructor(public payload: string) { }
}

export class FetchCartId {
    static readonly type = '[AUTH] FetchCartId';
    constructor() { }
}

export class SetLoginFrom {
  static readonly type = '[AUTH] SetLoginFrom';
  constructor(public payload: string) {}
}
// export class setNavigatedfrom {
//     static readonly type = '[AUTH] setNavigatedfrom';
//     constructor(public payload: string) {}
// }
export class SetAgreeToTerms {
  static readonly type = '[AUTH] SetAgreeToTerms';
  constructor(public payload: boolean) {}
}
export class SetCustomerRegistryFrom {
  static readonly type = '[AUTH] SetCustomerRegistryFrom';
  constructor(public payload: string) {}
}
