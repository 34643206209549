import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

// xplat
import { UIModule } from '@moduurnv2/web';
import { UISharedModule } from '@moduurnv2/features';
import {
  HeaderComponent,
  MenuComponent,
  ItemCountComponent,
  NotificationBarComponent,
  IconListComponent,
  FuturePickerComponent,
  PhoneInputComponent,
  ComboItemCountComponent,
  ComboCountComponent,
  CurbsideNotificationComponent 
  } from '.';
import { ModifierComponent } from './modifier/modifier.component';
import { PortionListComponent } from './modifier/components/portion-list/portion-list.component';
import { ModifierListComponent } from './modifier/components/modifier-list/modifier-list.component';
import { NgxsModule } from '@ngxs/store';
import { MenuItemState } from '@moduurnv2/libs-orderingapp/src';
import { FormsModule } from '@angular/forms';
import { CommonPopupComponent } from './common-popup/common-popup.component';
import { AddressListComponent } from './address-book/address-list.component';
import { AddressDetailsComponent } from './address-book/components/address-details/address-details.component';
import { AddressCardComponent } from './address-book/components/address-card/address-card.component';
import { AddressMapComponent } from './address-book/components/address-map/address-map.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { EmailChangePasswordComponent } from './email-change-password/email-change-password.component';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { MenuDesktopComponent } from './menu-desktop/menu-desktop.component';
import { ButtonDropDownComponent } from './button-drop-down/button-drop-down.component';
import { FooterDesktopComponent } from './footer-desktop/footer-desktop.component';
import { ModifierDesktopComponent } from './modifier/modifier-desktop/modifier-desktop.component';
import { ModifierWrapperComponent } from './modifier/modifier-wrapper/modifier-wrapper.component';
import { InlineTimePickerComponent } from './inline-time-picker/inline-time-picker.component';
import { TimeSwitchDisplayComponent } from './time-switch-display/time-switch-display.component';
import { VenueManagementModule } from './../venue-management/venue-management.module';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';
import { DealsApplyComponent } from './deals-apply/deals-apply.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeComponentComponent } from './qr-code-component/qr-code-component.component';
import { AddressBookModule } from './address-book-desktop/address-book-desktop.module';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';
import { OrderLevelModifierComponent } from './order-level-modifiers/modifier.component';
import {AddressWrapperComponent} from './address-wrapper/address-wrapper.component';
import { OrderModifierComponent } from './order-level-modifiers/modifier/modifier.component';
import { OrderLevelModifierListComponent } from './order-level-modifiers/modifier/components/modifier-list/modifier-list.component';
import { DatePipe } from '@angular/common';
import {StoreCloseNotificationBarComponent } from "./store-close-notification/store-close-notification.component"
@NgModule({
  imports: [
    UIModule,
    UISharedModule,
    NgxsModule.forFeature([MenuItemState]),
    FormsModule,
    DpDatePickerModule,
    VenueManagementModule,
    QRCodeModule,
    AddressBookModule
  ],
  declarations: [
    MenuComponent,
    HeaderComponent,
    ModifierComponent,
    PortionListComponent,
    IconListComponent,
    ModifierListComponent,
    ItemCountComponent,
    ComboItemCountComponent,
    ComboCountComponent,
    NotificationBarComponent,
    StoreCloseNotificationBarComponent,
    CommonPopupComponent,
    FuturePickerComponent,
    PhoneInputComponent,
    AddressListComponent,
    AddressCardComponent,
    AddressDetailsComponent,
    AddressMapComponent,
    EmailChangePasswordComponent,
    HeaderDesktopComponent,
    MenuDesktopComponent,
    ButtonDropDownComponent,
    ModifierDesktopComponent,
    FooterDesktopComponent,
    ModifierWrapperComponent,
    InlineTimePickerComponent,
    TimeSwitchDisplayComponent,
    AlertPopupComponent,
    DealsApplyComponent,
    QrCodeComponentComponent,
    ServiceUnavailableComponent,
    CurbsideNotificationComponent,
    AddressWrapperComponent,
    OrderLevelModifierComponent,
    OrderModifierComponent,
    OrderLevelModifierListComponent
  ],
  providers:[
    DatePipe
  ],
  exports: [
    UIModule,
    FormsModule,
    MenuComponent,
    HeaderComponent,
    ModifierComponent,
    PortionListComponent,
    IconListComponent,
    ModifierListComponent,
    ItemCountComponent,
    ComboItemCountComponent,
    ComboCountComponent,
    NotificationBarComponent,
    StoreCloseNotificationBarComponent,
    CommonPopupComponent,
    AddressListComponent,
    AddressCardComponent,
    FuturePickerComponent,
    PhoneInputComponent,
    AddressDetailsComponent,
    AddressMapComponent,
    EmailChangePasswordComponent,
    HeaderDesktopComponent,
    MenuDesktopComponent,
    FooterDesktopComponent,
    InlineTimePickerComponent,
    AlertPopupComponent,
    DealsApplyComponent,
    QrCodeComponentComponent,
    ServiceUnavailableComponent,
    CurbsideNotificationComponent,
    AddressWrapperComponent,
    OrderLevelModifierComponent,
    ButtonDropDownComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
