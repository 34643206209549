import { Component, OnInit, Input } from '@angular/core';
import {
  Delivery,
  DeliveryState,
  BaseComponent,
  FetchVenues,
  VenueManagementState,
  Venue,
  SetSelectedPickUpLocation,
  SharedService,
  ClearSelectedAddress,
  FetchDeliveryAddress,
  QRScanDetails,
  LocationState,
  WhitelabelState,
  FetchLocationDefaults,
  ClearQRScannedDetails
} from '@moduurnv2/libs-orderingapp/src/core';
import { SetSelectedDelivery } from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { FetchHomeData } from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
import { ClearCart, CartState, LocationDefaultState } from '@moduurnv2/libs-orderingapp/src/core';
import {TranslateService} from '@ngx-translate/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { RoomUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-update-selection/room-update-selection.component';
import { RoomTimeSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-time-selection/room-time-selection.component';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';

export class DeliveryMethodsBaseComponent extends BaseComponent
  implements OnInit {
  @Input('items') items: Delivery[]; 
  @Input('whiteLabelDet') whitelabel: any;
  @Input('selectedDelivery') selectedMethod: Delivery;
  // @Select(LocationDefaultState.getLocationDefaults)
  @Select(DeliveryState.getSelectedDelivery) deliveyMethod$: Observable<
    Delivery
  >;
  @Select(VenueManagementState.getVenues) venues$: Observable<Venue[]>;
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<QRScanDetails>;
  @Select(WhitelabelState.getQRScanDetails) qrScanDetails$: Observable<QRScanDetails>;
  public locationDefaults = this.store.selectSnapshot(
    LocationDefaultState.getLocationDefaults
  );
  public selectedDelivery = this.store.selectSnapshot(
    DeliveryState.getSelectedDelivery
  );
  public deliveryMethods = [
    {
      id: 'DELIVERYTYPE01',
      icon: '/assets/images/delivery-icon-big.svg',
      icon_inactive: '/assets/images/Delivery.svg',
      bg_color: '#FFD36F',
      gradient: {
        primary: '#FFD36F',
        secondary: '#E5A800',
      },
      icon_img :'/assets/images/Delivery.svg'
    },
    {
      id: 'DELIVERYTYPE02',
      icon: '/assets/images/takeout-icon-big.svg',
      icon_inactive: '/assets/images/Take Out Icon Big Inactive.svg',
      bg_color: '#FFB294',
      gradient: {
        primary: '#FFB294',
        secondary: '#F5875B',
      },
      icon_img :'/assets/images/Takeout.svg'
    },
    {
      id: 'DELIVERYTYPE03',
      icon: '/assets/images/tableservice-icon-big.svg',
      icon_inactive: '/assets/images/Table Service.svg',
      bg_color: '#8CE0F2',
      gradient: {
        primary: '#29B4D0',
        secondary: '#8CE0F2',
      },
      icon_img :'/assets/images/Table Service.svg'
    },
    {
      id: 'DELIVERYTYPE07',
      icon: '/assets/images/Curbside Delivery Icon Big.svg',
      icon_inactive: '/assets/images/Curbside Delivery Icon Big Inactive.svg',
      bg_color: '#F0B3DB',
      gradient: {
        primary: '#F0B3DB',
        secondary: '#D966B2',
      },
       icon_img :'/assets/images/Curbside.svg'
    },
    {
      id: 'DELIVERYTYPE04',
      icon: '/assets/images/postal.svg',
      icon_inactive: '/assets/images/Postal.svg',
      bg_color: '#C2BBEF',
      gradient: {
        primary: '#C2BBEF',
        secondary: '#7E71D5',
      },
      icon_img :'/assets/images/Postal.svg'
    },
    {
      id: 'DELIVERYTYPE05',
      icon: '/assets/images/Events Icon Big.svg',
      icon_inactive: '/assets/images/Events Icon Big Inactive.svg',
      bg_color: '#C2E0A8',
      gradient: {
        primary: '#C2E0A8',
        secondary: '#72AD3F',
      },
      icon_img :'/assets/images/Events Icon Big.svg'
    },
    {
      id: 'DELIVERYTYPE08',
      icon: '/assets/images/single-location-address.svg',
      icon_inactive: '/assets/images/single-location-address.svg',
      bg_color: '#FFB294',
      gradient: {
        primary: '#FFB294',
        secondary: '#F5875B',
      },
      icon_img :'/assets/images/single-location-address.svg'
    },
    {
      id: 'DELIVERYTYPE09',
      icon: '/assets/images/pickup-active.svg',
      icon_inactive: '/assets/images/pickup-inactive.svg',
      bg_color: '#F0B3DB',
      gradient: {
        primary: '#F0B3DB',
        secondary: '#D966B2',
      },
      icon_img :'/assets/images/Cafeteria Pickup.svg'
    },
    {
      id: 'DELIVERYTYPE10',
      icon: '/assets/images/classroom-delivery-active.svg',
      icon_inactive: '/assets/images/classroom-delivery-inactive.svg',
      bg_color: '#FFD36F',
      gradient: {
        primary: '#FFD36F',
        secondary: '#E5A800',
      },
      icon_img :'/assets/images/Classroom Delivery.svg'
    },
  ];
  isMobileView: boolean;
  qrScanDetails:QRScanDetails;
  selectedLocation; 
  constructor(public store: Store, public sweetAlert: SweetAlertService, public translate: TranslateService,private shared:SharedService,private modal:ModalService) {
    super();
    this.getSelectedDelivery();
    this.getSelectedLocation();
  }

  ngOnInit() {
    this.qrScanDetails$.pipe(takeUntil(this.destroy$)).subscribe((qrScanDetails)=>{
      if(qrScanDetails)
      this.qrScanDetails = qrScanDetails
    })    
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );    
  }
  
  ngOnChanges(){
    if(Config && Config.organizationId == '5f4d46e37e59e325b88dac4e'){
      this.translateDelivery(this.items);
    }
    if(this.items?.length>0){
      if(this.isMobileView)
      {
        if(this.selectedMethod && this.selectedMethod?._id)
        {
          this.items.forEach(element => {
            element['deliveryTypeSelected'] =  (this.selectedMethod && element._id == this.selectedMethod?._id) ? true : false;})
        }else if(this.selectedDelivery)
        {
          this.items.forEach(element => {
            element['deliveryTypeSelected'] =  (this.selectedDelivery && element._id == this.selectedDelivery?._id) ? true : false;})  
        }else
        {
          this.items.forEach((element, index) => 
          {
            if(this.locationDefaults?.locationData?.orderSettings?.hideTableServiceSelection == true && 
              element.textCode == "DELIVERYTYPE03" && index == 0)
              {
                this.items[1]['deliveryTypeSelected'] = true;
              }else
              {
                this.items[0]['deliveryTypeSelected'] = true;
              }
            })
        }
      }else
      {
        this.items.forEach(element => {
          element['deliveryTypeSelected'] =  (this.selectedMethod && element._id == this.selectedMethod?._id) ? true : false;
        })
      }
    }
    this.whitelabel = this.whitelabel; 
    this.store.dispatch(new FetchLocationDefaults())    
  }

  getIcon(textCode) {
    let obj = this.deliveryMethods.find((o) => o.id === textCode);
    return obj
      ? this.selectedMethod.textCode === textCode
        ? obj.icon
        : obj.icon_inactive
      : '';
  }
  async onClick(item) {        
    if (this.selectedMethod && this.selectedMethod.textCode == item.textCode)
      return;

    let selectedCart = this.store.selectSnapshot(CartState.getCart);
    if (selectedCart) {
      let vm = this;
      await this.sweetAlert
        .confirm(
          this.translate.instant('are-you-sure'),
          this.translate.instant('another-delivery-method')
           )
        .then((choice) => {
          if (choice) {
            this.actionOnDeliveryChange(item);
              this.store.dispatch(new ClearSelectedAddress());
          } else {
            return;
          }
        });
    } else {
      if(item.textCode == 'DELIVERYTYPE10'){
      //  let selectedRoom = this.store.selectSnapshot(VenueManagementState.getSelectedRoom);
      //  if(!selectedRoom){
         this.forceUserSelectVenue(item);
      //  } else {
      //   this.actionOnDeliveryChange(item);
      //  }
      } else {
        this.actionOnDeliveryChange(item);
      }
    }
    this.store.dispatch(new FetchLocationDefaults())
  }


  getStyle(textCode) {
    let obj = this.deliveryMethods.find((o) => o.id === textCode);
    if (obj) {
      if (this.selectedMethod.textCode === obj.id) {
        return {
          'box-shadow': '4px 4px 7px ' + obj.gradient.secondary + '66',
          background: `transparent linear-gradient(225deg, ${obj.gradient.primary} 0%, ${obj.gradient.secondary} 100%) 0% 0% no-repeat padding-box`,
        };
      } else {
        return {
          'background-color': obj.bg_color,
        };
      }
    }
  }

  getSelectedDelivery() {
    this.deliveyMethod$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedMethod) => {
        if (selectedMethod) {
          if(this.selectedMethod?.text == undefined){
            this.selectedMethod = this.selectedDelivery;
          }else{
            this.selectedMethod = selectedMethod;
          }
        }
      });
  }

  getSelectedLocation(){
    this.selectedLocation$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedLocation) => {
        if (selectedLocation) {
          this.selectedLocation = selectedLocation;
        }
      });
    
  }
  setDefaultVenue(deliveryTypeTextCode){
    if(deliveryTypeTextCode == 'DELIVERYTYPE09'){
      let filter = {
        tableType: 'TABLETYPE06'
      };
      this.store.dispatch(new FetchVenues(filter));
      this.venues$.pipe(takeUntil(this.destroy$)).subscribe((venues) => {
        if (venues && venues.length == 1) {
          let defaultPickupStation = venues[0];
          if (defaultPickupStation) {
            this.store.dispatch(
              new SetSelectedPickUpLocation(defaultPickupStation)
            );
          }
        } 
      });
    }
  }

  translateDelivery(deliveryTypes){

    if(deliveryTypes && deliveryTypes.length){
      deliveryTypes.map(type=>{
        type.text = this.translateDeliveryText(type);
      });
    }
   
  }

  translateDeliveryText(delivery){
    switch(delivery.text){
      case 'Cafe Pickup' : {
        return  this.translate.instant('cafe-pickup');
      }break;
     
      case 'Classroom Delivery' : {
        return this.translate.instant('classroom-delivery');
      }break;

     default : return delivery.text ;
      break;
    }
  }

  actionOnDeliveryChange(item){
    if(this.selectedMethod?.textCode =='DELIVERYTYPE04' && item.textCode == 'DELIVERYTYPE01'){
      this.store.dispatch(new ClearSelectedAddress());
      localStorage.removeItem('selectedAdrsLocation')
      this.store.dispatch(new FetchDeliveryAddress());
    }
    this.selectedMethod = item;
    this.store.dispatch(
      new SetSelectedDelivery(this.selectedMethod.textCode)
    );
    this.store.dispatch(new FetchHomeData());
    this.setDefaultVenue(this.selectedMethod.textCode);
    this.store.dispatch(new ClearCart('eta')); //Changes for MV2-166
    this.store.dispatch(new ClearQRScannedDetails())
  }

  forceUserSelectVenue(item) {
    this.selectedMethod = item;
    this.store.dispatch(
      new SetSelectedDelivery(this.selectedMethod.textCode)
    );
   // this.shared.display.subscribe(isMobileView=>this.isMobile=isMobileView);
    if (this.isMobileView) {
      this.modal.showModal(RoomTimeSelectionComponent,{
        whenClosed: (data) => {
          this.actionOnDeliveryChange(item);
        },
      });
    } else {
      this.modal.showMediumModalDesktop(RoomUpdateSelectionComponent,{data:{
        disableOverlayClickClosing:true
      },
        whenClosed: (data) => {
          this.actionOnDeliveryChange(item);
        },
      });
    }
  }


}
